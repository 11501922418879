<div class="sh-slider-container">
  <div class="sh-slider--input__flex">
    <button class="sh-slider--button" mat-icon-button (click)="decrease()"><mat-icon>skip_previous</mat-icon></button>
    <div class="sh-w100 sh-border--bottom__light pl-3 pr-3">
      <input type="range"
             [ngModel]="value"
             (ngModelChange)="valueChange.emit($event)"
             [step]="step"
             [min]="min"
             [max]="max"
             class="slider"
             [class]="customCSSClass"
             (input)="handleInput($event)"
             #shRangeSlider>
      <div class="sh-slider-values">
        <span *ngIf="prefix !== ''" class="sh-slider-prefix">{{prefix}}</span>
        <input class="sh-slider--input-field" type="number" [(ngModel)]="value" (input)="handleInput($event)">
<!--        <span>{{value}}</span>-->
        <span *ngIf="suffix !== ''" class="sh-slider-suffix">{{suffix}}</span>
      </div>
    </div>
    <button class="sh-slider--button" mat-icon-button (click)="increase()"><mat-icon>skip_next</mat-icon></button>
  </div>
</div>
