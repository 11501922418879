<section class="sh-shopitem--container">
  <sh-shop-item-header [commodityName]="shopItemToEdit.commodity.name"
                       [iconSrc]="'/assets/icons/commodities/Inv_Icon_'+shopItemToEdit.commodity.name+'.svg'"
                       [maxInventory]="shopItemToEdit.maxInventoryCscu"
                       [pricePerUnit]="shopItemToEdit.pricePerItem"
                       ></sh-shop-item-header>
  <sh-range-slider suffix="cSCU" min="0" max="50000" step="1"
                     [value]="shopItemToEdit.maxInventoryCscu"
                   (valueChange)="updateShopItemPrice($event)"></sh-range-slider>
  <sh-shop-item-price-input [(currentTradeType)]="shopItemToEdit.tradeType"
                            [(itemPrice)]="shopItemToEdit.pricePerItem"></sh-shop-item-price-input>
  <sh-shop-item-actions saveVisible="true" cancelVisible="true" editVisible="false" deleteVisible="true"
                        (cancelAction)="resetShopItem()" (saveAction)="saveShopItem()" (deleteAction)="deleteShopItem()"></sh-shop-item-actions>
  <div>

  </div>
</section>
