<section class="sh-item-price--container">
  <div class="sh-item-price--input-container">
    <img src="/assets/icons/icon_currency.svg" height="16" class="sh-item-price--icon__white">
    <input type="number" class="sh-item-price--input-field" [ngModel]="itemPrice"
           (ngModelChange)="itemPriceChange.emit($event)">
  </div>

  <button mat-button class="sh-item-price--menu-button"
          [matMenuTriggerFor]="tradeTypeMenu">{{currentTradeType}}</button>
  <mat-menu class="sh-item-price--menu__entries" #tradeTypeMenu xPosition="after">
    <button mat-menu-item value="Buy"
            (click)="setSelectedTradeType(TradeTypes.buy)">Buy</button>
    <button mat-menu-item value="Sell" (click)="setSelectedTradeType(TradeTypes.sell)">Sell</button>
  </mat-menu>
</section>
