import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class ShDurationPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    let input = value;
    let seconds = input % 60;
    let  minutes = 0;
    let hours = 0;
    let result = '';
    const lang = args[0];
    const hrsStr = lang === 'de' ? 'std' : 'h';

    if (seconds > 0) {
      input -= seconds;
    }

    if (input > 3600) {
      minutes = input % 3600;
      input -= minutes;
      minutes /= 60;
      hours = input / 3600
      result = result.concat(hours + 'std ');
    } else {
      minutes = input / 60;
    }
    result = result.concat(minutes + 'min ')
    result = result.concat(this.pad(seconds, 2) + 's');

    return result;
  }

  pad(num: number, size: number): string {
    let s = num.toFixed() + '';
    while (s.length < size) { s = '0' + s; }
    return s;
  }

}
