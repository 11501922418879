import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { Vehicle } from "@starhead/entities/vehicle/vehicle";
import { Organization } from "@starhead/entities/organization";
import { ManufacturerService } from "@starhead/service/manufacturer.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'sh-ship-card',
  templateUrl: './ship-card.component.html',
  styleUrls: ['./ship-card.component.scss']
})
export class ShipCardComponent implements OnInit, AfterViewInit {
  @Input() selectedShip: Vehicle;

  manufacturer: Organization;
  manufacturer$: Observable<Organization>;
  imageUrl: string;

  constructor(private manufacturerService: ManufacturerService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.toastr.info(this.selectedShip?.manufacturer?.code);
    this.imageUrl = (this.selectedShip.thumbnailUrl?.length) ? this.selectedShip?.thumbnailUrl : 'assets/images/vehicle_default.png';
  }

  ngAfterViewInit() {
  }

}
