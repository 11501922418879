import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpHeaders
} from "@angular/common/http";
import { Observable } from 'rxjs';
import { KeycloakService } from "keycloak-angular";
import { KeycloakInstance } from "keycloak-js";
import { AccountService } from "@starhead/service/account.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private keycloakInstance: KeycloakInstance;
  constructor(private keycloak: KeycloakService, private accountService: AccountService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.keycloakInstance = this.keycloak.getKeycloakInstance();
    const token = this.accountService.getToken();

    this.accountService.tryRefreshAccesstoken();

    if(this.keycloakInstance.authenticated) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return next.handle(request);
  }
}
