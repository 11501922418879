import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from "@angular/core";
import { HardpointSize } from "@starhead/core/interfaces/vehicles/hardpoints/hardpoint-size";
import { VehicleComponent } from "@starhead/core/interfaces/vehicles/components/vehicle-component";
import { ShipComponentService } from "@starhead/service/ship-component.service";
import { QuantumDrive } from "@starhead/entities/quantum-drive";
import { LoadoutItem } from "@starhead/entities/vehicle/loadout-item";
import { VehicleLoadout } from "@starhead/entities/vehicle/vehicle-loadout";
import { VehicleHardpoint } from "@starhead/entities/vehicle/vehicle-hardpoint";
import { LoggerService } from "@starhead/service/logger.service";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { HardpointType } from "@starhead/core/interfaces/vehicles/hardpoints/hardpoint-type";
import { VehicleComponentsComponent } from "../../../../app/administration/admin-vehicles/vehicle-components/vehicle-components.component";

@Component({
  selector: 'sh-vehicle-component-selector',
  templateUrl: './vehicle-component-selector.component.html',
  styleUrls: ['./vehicle-component-selector.component.scss'],
})
export class VehicleComponentSelectorComponent
  implements OnInit, AfterViewInit
{
  @Input() componentSize: HardpointSize;
  @Input() loadoutItem?: LoadoutItem;
  @Input() hardpointType: HardpointType;
  @Input() vehicleSlot?: VehicleHardpoint;

  @Output() componentSave = new EventEmitter<VehicleComponent>();

  vehicleComponents: VehicleComponent[];
  quantumDrives: VehicleComponent[];
  selectedComponent: VehicleComponent;
  selectedComponentSource = new Subject<VehicleComponent>();
  selectedComponent$ = this.selectedComponentSource.asObservable();
  loadout: VehicleLoadout;

  qdSelectionOpen = false;

  constructor(private componentService: ShipComponentService, private logger: LoggerService) {}

  ngOnInit(): void {

    this.selectedComponent$.subscribe((x) => {
      this.selectedComponent = x;
      this.componentSave.emit(x);
    });

    if(this.loadoutItem) {
      this.componentService.getVehicleComponentById(this.loadoutItem.componentId).subscribe(x => {
        this.selectedComponentSource.next(x);
      });
    }
    this.vehicleComponents?.map((component) => {
      if(component.id === this.loadoutItem.componentId) {
        this.setSelectedComponent(component);
        this.componentSave.emit(this.selectedComponent);
      }
    });
  }

  ngAfterViewInit() {
    this.componentService.getVehicleComponentWithFilters(null, this.componentSize?.id, this.hardpointType?.id).subscribe((result) => {
      this.vehicleComponents = result;
    });
  }

  toggleQdSelection() {
    this.qdSelectionOpen = !this.qdSelectionOpen;
  }

  setSelectedComponent(component: VehicleComponent){
    this.selectedComponentSource.next(component);
    this.qdSelectionOpen = false;
  }

}
