import { Injectable } from '@angular/core';
import { Loglevel } from '@starhead/core/enums/loglevel';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  LOGLEVEL = environment.logLeven;
  private logStyleString = 'background-color: black; color: blue';
  private warnMsgStyle = 'background-color: transparent; color: orange';
  private errorMsgStyle = 'background-color: black; color: red;';
  private infoMsgStyle = 'background-color: black; color: orange;';
  private debugMsgStyle = 'background-color: lightblue; color: black;';
  private successMsgStyle = 'background-color: black; color: green;';

  constructor() {}

  /// Logs a message, the calling Component, which Method and optional parameters.
  infoWithId(message: string, componentName: string, methodName: string, ...object: any): void {
    if(this.LOGLEVEL > Loglevel.INFO) return;
    console.group('%c[INFO]', this.infoMsgStyle);
    console.log(
      `%c[${componentName}]::[${methodName}] Message: ${message}`,
      this.infoMsgStyle
    );
    if(object) {
      console.log('%c', this.infoMsgStyle, object);
    }
    console.groupEnd()
  }

  debugWithId(
    message: string,
    componentName: string,
    methodName: string,
    ...object: any
  ): void {

    if (this.LOGLEVEL > Loglevel.DEBUG) {
      return;
    }
    console.group('%c[DEBUG]', this.debugMsgStyle);
      console.log(
        `%c[${componentName}]::[${methodName}] > ${message}`,
        this.debugMsgStyle
      );
      if(object) {
        console.log('%c', this.debugMsgStyle, object);
      }
      console.groupEnd()
  }

  warningWithId(
    message: string,
    componentName: string,
    methodName: string,
    object?: object
  ): void {
    if(this.LOGLEVEL > Loglevel.WARNING) {
      return;
    }

    console.warn(`[WARNING]\n%c[${componentName}]::[${methodName}]==>${message}`, this.warnMsgStyle);
  }

  errorWithId(
    message: string,
    componentName: string,
    methodName: string,
    ...object: any[]
  ): void {
    console.group('[ERROR]')
    console.error(`\n%c[${componentName}]::[${methodName}]==>${message}`, this.errorMsgStyle);
    if(object) {
      console.log('%c', this.warnMsgStyle, object);
    }
    console.groupEnd()
  }
}
