import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { HardpointType } from "@starhead/core/interfaces/vehicles/hardpoints/hardpoint-type";
import { HardpointSize } from "@starhead/core/interfaces/vehicles/hardpoints/hardpoint-size";
import { VehicleHardpoint } from "@starhead/entities/vehicle/vehicle-hardpoint";
import { VehicleSlotCreateDto } from "@starhead/core/dtos/vehicle-slot-create-dto";
import { LoggerService } from "@starhead/service/logger.service";

@Component({
  selector: 'sh-hardpoint-card',
  templateUrl: './hardpoint-card.component.html',
  styleUrls: ['./hardpoint-card.component.scss']
})
export class HardpointCardComponent implements OnInit {
  @Input() hardpoint: VehicleHardpoint;
  @Input() hardpointTypes: HardpointType[];
  @Input() hardpointSizes: HardpointSize[];
  @Output() removeHardpoint = new EventEmitter<VehicleHardpoint>();
  @Output() hardpointChanged = new EventEmitter<VehicleSlotCreateDto>();

  hardpointDto: VehicleSlotCreateDto;

  form: FormGroup;
  // FormControls
  hardpointTypeSelect = new FormControl('');
  hardpointSizeSelect = new FormControl('');
  hardpointQuantityInput = new FormControl('');

  constructor(private fb: FormBuilder, private logger: LoggerService) { }

  ngOnInit(): void {
    this.hardpointDto = {
      hardpointSizeId: this.hardpoint.size.id,
      hardpointTypeId: this.hardpoint.hardpointType.id,
      quantity: this.hardpoint.quantity
    };

    this.form = this.fb.group({
      hardpointTypeId: new FormControl(''),
      hardpointSizeId: new FormControl(''),
      quantity: new FormControl('')
    });

    this.form.valueChanges.subscribe((value) => {
      this.logger.debugWithId('Hardpoint property changed', HardpointCardComponent.name, this.ngOnInit.name, value);
      this.hardpointDto = value;
      this.changeHandler();
    });
    this.patchFormControls(this.hardpointDto);
  }

  patchFormControls(hardpoint: VehicleSlotCreateDto): void {
    this.form.patchValue(hardpoint);
  }

  onRemoveHardpoint(hardpoint: VehicleHardpoint): void {
    this.removeHardpoint.emit(hardpoint);
  }

  changeHandler() {
    this.hardpointChanged.emit(this.hardpointDto);
  }

}
