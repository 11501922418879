import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {QuantumDrive} from '@starhead/core/entities/quantum-drive';
import {map} from 'rxjs/operators';
import { HardpointSize } from "@starhead/core/interfaces/vehicles/hardpoints/hardpoint-size";
import { VehicleComponent } from "@starhead/core/interfaces/vehicles/components/vehicle-component";
import { VehicleComponentListDto } from "@starhead/core/interfaces/vehicles/components/vehicle-component-list-dto";
import { HardpointParamterValueDto } from "@starhead/core/interfaces/vehicles/hardpoints/hardpoint-paramter-value-dto";
import { CreateComponentDto } from "@starhead/core/interfaces/vehicles/dtos/create-component-dto";
import { HardpointType } from "@starhead/core/interfaces/vehicles/hardpoints/hardpoint-type";
import { VehicleHardpoint } from "@starhead/entities/vehicle/vehicle-hardpoint";
import { VehicleSlotCreateDto } from "@starhead/core/dtos/vehicle-slot-create-dto";

@Injectable({
  providedIn: 'root',
})
export class ShipComponentService {
  componentUrl = environment.apiUrl + '/vehiclecomponent';
  hardpointUrl = environment.apiUrl + '/hardpoint';

  constructor(private http: HttpClient) {}

  getQuantumDrives(): Observable<QuantumDrive[]> {
    return this.http.get<any[]>('../assets/tmp/quantumdrive_data.json').pipe(
      map((x) => {
        const ret: QuantumDrive[] = [];
        x.forEach((data) => {
          ret.push(data.data);
        });
        return ret;
      })
    );
  }
  getQuantumFuelTanks(): void {
    // todo: get quantum fuel tanks
  }
  getShieldEmitters(): void {
    // todo: get quantum shield emitters
  }
  getThrustersMain(): void {
    // todo: get quantum thrusters main
  }
  getCoolers(): void {
    // todo: get quantum coolers
  }
  getFuelIntakes(): void {
    // todo: get fuel intakes
  }
  getFuelTanks(): void {
    // todo: get fuel tanks
  }

  getVehicleComponents(): Observable<VehicleComponent[]> {
    return this.http.get<VehicleComponent[]>(this.componentUrl);
  }

  getVehicleComponentById(id: number): Observable<any> {
    return this.http.get(`${this.componentUrl}/${id}`);
  }

  getVehicleSlotsFor(id: number): Observable<VehicleHardpoint[]> {
    return this.http.get<VehicleHardpoint[]>(
      `${environment.apiUrl}/vehicle/${id}/slot`
    );
  }

  getVehicleComponentWithFilters(
    manufacturerFilter?: number,
    sizeFilter?: number,
    typeFilter?: number
  ): Observable<VehicleComponent[]> {
    let filterUrl = this.componentUrl;
    let params = new HttpParams();
    if (manufacturerFilter) {
      params = params.append('manufacturerFilter', manufacturerFilter);
    }
    if (sizeFilter) {
      params = params.append('sizeFilter', sizeFilter);
    }
    if (typeFilter) {
      params = params.append('typeFilter', typeFilter);
    }
    return this.http.get<VehicleComponent[]>(this.componentUrl, {params: params});
  }

  updateVehicleComponentParameter(
    id: number,
    hardpointParameters: HardpointParamterValueDto[]
  ): Observable<any> {
    return this.http.put<any>(
      `${this.componentUrl}/${id}/parameter/value`,
      hardpointParameters
    );
  }

  getHardpoints(): Observable<any> {
    return this.http.get(this.hardpointUrl);
  }

  getHardpointsForVehicle(id: number): Observable<VehicleHardpoint[]> {
    return this.http.get<VehicleHardpoint[]>(`${environment.apiUrl}/vehicle/${id}/slot`);
  }

  getHardpointTypes(): Observable<HardpointType[]> {
    return this.http.get<HardpointType[]>(this.hardpointUrl + '/type');
  }

  getHardpointSizes(): Observable<HardpointSize[]> {
    return this.http.get<HardpointSize[]>(this.hardpointUrl + '/size');
  }

  createComponent(
    component: CreateComponentDto
  ): Observable<VehicleComponentListDto> {
    return this.http.post<VehicleComponentListDto>(
      this.componentUrl,
      component
    );
  }

  createSlotsForVehicle(id: number, hardpointDtos: VehicleSlotCreateDto[]): Observable<VehicleHardpoint[]> {

    return this.http.post<VehicleHardpoint[]>(`${environment.apiUrl}/vehicle/${id}/slot`, hardpointDtos);
  }
}
