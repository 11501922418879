import { Injectable } from '@angular/core';
import {Quest} from '@starhead/core/interfaces/quests/quest';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {QuestForm} from '@starhead/core/interfaces/forms/quest-form';
import {map} from 'rxjs/operators';
import {ShFormControl} from '@starhead/core/interfaces/forms/sh-form-control';
import { DefaultForm } from "@starhead/core/interfaces/forms/default-form";

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(private http: HttpClient) { }

  loadQuestFormData(questType: string): Observable<QuestForm> {
    return this.http.get<ShFormControl[]>('../../assets/form-configs/' + questType + 'Form.json').pipe(
      map(controls => {
        return {
          titleDe: controls['titleDe'],
          titleEn: controls['titleEn'],
          formControls: controls
        };
      })
    );
  }

  saveFormData(formData): void {
    console.log(formData);
  }

  loadFormData(formType: string): Observable<DefaultForm> {
    return this.http.get<ShFormControl[]>('../../assets/form-configs/' + formType + 'Form.json').pipe(
      map(controls => {
        return {
          titleDe: controls['titleDe'],
          titleEn: controls['titleEn'],
          formControls: controls
        };
      })
    );
  }
}
