import {
    Component,
    Input,
    Output,
    OnInit,
    EventEmitter,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { Commodity } from '@starhead/entities/trading/commodity';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'sh-commodity-list',
    templateUrl: './commodity-list.component.html',
    styleUrls: ['./commodity-list.component.scss'],
})
export class CommodityListComponent implements OnInit, OnChanges {
    searchInput: FormControl;
    @Input() commodities: Commodity[] = [];
    @Output() commoditySelected = new EventEmitter<Commodity>();
    filteredCommodities: Commodity[] = [];
    scrollTop: number;
    selectedOptions: number[] = [];

    constructor() {}

    ngOnInit(): void {
        this.searchInput = new FormControl(['']);
        this.searchInput.valueChanges.subscribe((value) => {
            console.log(value);
            this.filteredCommodities = this._filterCommodities(value);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.commodities) {
            if (changes.commodities.currentValue.length > 0) {
                this.selectedOptions = [this.commodities[0].id];
                this.selectCommodity(this.commodities[0]);
            }
            this.filteredCommodities = [...this.commodities];
        }
    }

    selectCommodity(com: Commodity): void {
        this.commodities.forEach((c) => {
            if (c.id === com.id) {
                return;
            }
        });
        this.commoditySelected.emit(com);
    }

    private _filterCommodities(value: string) {
        const filterValue = value ?? '';
        return (
            this.commodities.filter((c) =>
                c.name.toLowerCase().includes(filterValue),
            ) || this.commodities
        );
    }

    resetAutoInput() {
        this.searchInput.patchValue('');
        this.filteredCommodities = this._filterCommodities('');
    }
}
