<nav id="navmain" class="navbar navbar-expand-lg sh-navbar sh-bg-transparent">
    <div>
        <button
            class="navbar-toggler sh-btn sh-btn-outline-accent no-outline"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSecondaryNav">
            <span class="sh-text-light">Navigation</span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSecondaryNav">
            <ul class="sh-nav-menu-list navbar-nav">
                <li
                    class="sh-nav-list-item nav-item"
                    data-bs-toggle="collapse"
                    data-bs-target="#newsNav"
                    (click)="toggleSelectedNav('newsNavSelected')">
                    <button
                        class="sh-nav-link"
                        routerLink="/"
                        [class.sh-active]="activeRoute === ''">
                        Startseite
                    </button>
                </li>
                <li
                    id="database"
                    disabled
                    class="sh-nav-list-item nav-item"
                    data-bs-toggle="collapse"
                    data-bs-target="#dbSubnavContent"
                    data-bs-parent="#navmain"
                    (click)="toggleSelectedNav('databaseNavSelected')"
                    routerLinkActive="sh-active">
                    <button
                        class="sh-nav-link"
                        dropdown
                        triggers="mouseover"
                        [disabled]="true"
                        [class.sh-active]="activeRoute === 'database'">
                        Datenbank
                    </button>
                </li>
                <li
                    id="tools"
                    class="sh-nav-list-item nav-item"
                    data-bs-toggle="collapse"
                    data-bs-target="#toolsNav"
                    data-bs-parent="#navmain"
                    (click)="toggleSelectedNav('trading')"
                    routerLinkActive="sh-active">
                    <button
                        class="sh-nav-link"
                        [class.sh-active]="activeRoute === 'tools'">
                        Tools
                    </button>
                </li>
                <li
                    id="guides"
                    class="disabled sh-nav-list-item nav-item"
                    [class.sh-active]="guidesNavSelected"
                    data-bs-toggle="collapse"
                    data-bs-target="#guidesNav"
                    (click)="toggleSelectedNav('guidesNavSelected')"
                    disabled="disabled">
                    <button class="sh-nav-link" disabled>Guides</button>
                </li>
                <li
                    id="community"
                    class="disabled sh-nav-list-item nav-item"
                    [class.sh-active]="communityNavSelected"
                    data-bs-toggle="collapse"
                    data-bs-target="#communityNav"
                    (click)="toggleSelectedNav('communityNavSelected')"
                    disabled="disabled">
                    <button disabled class="sh-nav-link">Community</button>
                </li>
                <li id="priceTool" class="sh-nav-list-item nav-item">
                    <button
                        class="sh-nav-link"
                        routerLink="price-tool"
                        [class.sh-active]="activeRoute === 'price-tool'"
                        [disabled]="!accountService.isUserLoggedIn()">
                        Preis Tool
                    </button>
                </li>
                <li id="feedback" class="sh-nav-list-item nav-item">
                    <button
                        class="sh-nav-link"
                        (click)="openFeedbackDialog()"
                        [class.sh-active]="activeRoute === 'feedback'"
                        [disabled]="!accountService.isUserLoggedIn()">
                        Feedback
                    </button>
                </li>
            </ul>
        </div>
    </div>
</nav>
<div
    class="sh-navbar navbar-expand-lg collapse"
    id="dbSubnavContent"
    data-bs-parent="#navmain">
    <div class="sh-divider-horizontal"></div>
    <ul class="sh-subnav-list navbar-nav">
        <li id="vehicles" class="sh-subnav-item dropdown nav-item">
            <div
                class="sh-btn-nav dropdown-toggle"
                type="button"
                id="shipMenu"
                data-bs-toggle="dropdown">
                <img src="../../../../assets/icons/navigation/ships.png" />
                <span>Schiffe</span>
            </div>
            <ul
                class="dropdown-menu sh-dropdown-menu"
                role="menu"
                aria-labelledby="shipMenu">
                <li class="manufacturer-grid">
                    <div
                        class="manufacturer-grid-item"
                        [routerLink]="['/database/ships', 'all']">
                        <label>Alle Schiffe</label>
                    </div>
                    <div
                        class="manufacturer-grid-item"
                        *ngFor="let man of manufacturers"
                        [routerLink]="[
                            '/database/ships',
                            man.code.toLowerCase()
                        ]">
                        <label>{{ man.name }}</label>
                        <div>
                            <img
                                class="sh-manufacturer-select-logo"
                                src="assets/logos/manufacturers/{{
                                    man.code
                                }}_WHITE.png" />
                        </div>
                    </div>
                </li>
            </ul>
        </li>
        <li
            id="equipment"
            class="sh-subnav-item"
            data-bs-toggle="collapse"
            data-bs-target="#equipmentMenu">
            <div class="sh-btn-nav">
                <img src="../../../../assets/icons/navigation/items.png" />
                <span>Items</span>
            </div>
        </li>
        <li
            id="shops"
            class="sh-subnav-item"
            data-bs-toggle="collapse"
            data-bs-target="#shopsMenu">
            <div class="sh-btn-nav">
                <img src="../../../../assets/icons/navigation/shops.png" />
                <span>Shops</span>
            </div>
        </li>
        <li
            id="locations"
            class="sh-subnav-item"
            data-bs-toggle="collapse"
            data-bs-target="#locationsMenu">
            <div class="sh-btn-nav">
                <img src="../../../../assets/icons/navigation/location.png" />
                <span>Orte</span>
            </div>
        </li>
        <li
            id="planets"
            class="sh-subnav-item"
            data-bs-toggle="collapse"
            data-bs-target="#planetsMenu">
            <div class="sh-btn-nav">
                <img src="../../../../assets/icons/navigation/planets.png" />
                <span>Planeten</span>
            </div>
        </li>
    </ul>

    <div
        class="collapse sh-navbar"
        id="equipmentMenu"
        data-bs-parent="#dbSubnavContent">
        <ul class="sh-subnav-list">
            <li class="sh-subnav-item" routerLink="/database/armor">
                Rüstungen
            </li>
        </ul>
    </div>
</div>
<div
    class="collapse navbar sh-navbar sh-border-top-accent"
    id="toolsNav"
    data-bs-parent="#navmain">
    <div>
        <ul class="sh-nav-menu-list">
            <li class="sh-nav-list-item">
                <span class="sh-nav-link" routerLink="/trading/routes">
                    Routenplaner
                </span>
            </li>
            <li class="sh-nav-list-item">
                <span class="sh-nav-link" routerLink="/trading/commodities">
                    Warenübersicht
                </span>
            </li>
        </ul>
    </div>
</div>
