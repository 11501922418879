import {Directive, ElementRef, EmbeddedViewRef, Input, OnDestroy, OnInit, Renderer2, ViewContainerRef} from '@angular/core';
import {Subscription} from 'rxjs';
import {ShDropdownState} from '@starhead/core/directives/sh-dropdown.state';
import {ShDropdownConfig} from '@starhead/core/data/sh-dropdown.config';
import {AnimationBuilder, AnimationFactory} from '@angular/animations';
import {NavMenuDirective} from '@starhead/core/directives/nav-menu.directive';

@Directive({
  selector: '[shDropdown]',
  exportAs: 'sh-dropdown',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.open]': 'isOpen',
    '[class.show]': 'isOpen'
  }
})
export class DropdownDirective implements OnInit, OnDestroy{

  @Input() triggers?: string;
  @Input() container?: string;

  @Input()
  set autoClose(value: boolean) {
    this.state.autoClose = value;
  }

  get autoClose(): boolean {
    return this.state.autoClose;
  }

  @Input()
  set isAnimated(value: boolean) {
    this.state.isAnimated = value;
  }

  get isAnimated(): boolean {
    return this.state.isAnimated;
  }

  @Input()
  set insideClick(value: boolean) {
    this.state.insideClick = value;
  }

  get insideClick(): boolean {
    return this.state.insideClick;
  }

  @Input()
  set isDisabled(value: boolean) {
    this._isDisabled = value;
    this.state.isDisabledChange.emit(value);
    if (value) {
      this.hide();
    }
  }

  get isDisabled(): boolean {
    return this._isDisabled;
  }

  @Input()
  set isOpen(value: boolean) {
    if (value) {
      this.show();
    } else {
      this.hide();
    }

    this.state.isOpen = value;
  }

  get isOpen(): boolean {
    return this.state.isOpen;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _isDisabled = false;
  private subscriptions: Subscription[] = [];
  private isInitialized = false;
  private menu: EmbeddedViewRef<NavMenuDirective>;
  private factoryDropDownAnimation: AnimationFactory;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private viewContainer: ViewContainerRef,
    private state: ShDropdownState,
    private config: ShDropdownConfig,
    builder: AnimationBuilder
  ) {
    this.state.isAnimated = this.config.isAnimated;
    this.state.autoClose = this.config.autoClose;
    this.state.insideClick = this.config.insideClick;
    this.state.isOpen = false;
  }


  ngOnDestroy(): void {
    this.subscriptions = null;
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    // do nothing
  }

  show(): void {
    if (this.isOpen) {
      return;
    }
    this.addShowClass();
  }

  hide(): void {
    if (!this.isOpen) {
      return;
    }
    this.removeShowClass();
  }

  playAnimation(): void {
    if (this.state.isAnimated) {
      setTimeout(() => {
        this.factoryDropDownAnimation.create(this.menu.rootNodes[0]).play();
      });
    }
  }

  addShowClass(): void {
    this.renderer.addClass(this.menu.rootNodes[0], 'show');
  }

  removeShowClass(): void {
    this.renderer.removeClass(this.menu.rootNodes[0], 'show');
  }

}
