import { Component, OnInit } from '@angular/core';
import {FormGroup, FormArray, FormControl, Validators} from '@angular/forms';
import { Quest } from '@starhead/core/interfaces/quests/quest';
import {FormService} from '@starhead/service/form.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {QuestForm} from '@starhead/core/interfaces/forms/quest-form';
import {ShFormControl} from '@starhead/core/interfaces/forms/sh-form-control';

@Component({
  selector: 'sh-quest-form',
  templateUrl: './quest-form.component.html',
  styleUrls: ['./quest-form.component.scss']
})
export class QuestFormComponent implements OnInit {
  mainForm: FormGroup;
  public questType: string;
  questForm: QuestForm;
  formData: any;

  constructor(private formService: FormService, public bsModalRef: BsModalRef) {
    this.mainForm = new FormGroup({});
  }

  ngOnInit(): void {
    this.bsModalRef.setClass('sh-modal');
  }

  loadFormData(questType: string): void {
    this.formService.loadFormData(questType).subscribe(data => {
      this.questForm = data;

      for (const formControl of this.questForm.formControls) {
        const fc = this.createControl(formControl);

        this.mainForm.addControl(String(formControl.id), fc);
      }
    }, error => {
      console.log(error);
    });
  }

  createControl(fc: ShFormControl): FormControl {
    const validators = [];
    if (fc.required) {
      validators.push(Validators.required);
    }
    return new FormControl('', validators);
  }

  saveData(value): void {
    this.formData = value;
    console.log(this.questForm);

    this.bsModalRef.hide();
  }

}
