import {KeycloakService} from 'keycloak-angular';
import { environment } from "../../environments/environment";

export function initializeKeycloak(keycloak: KeycloakService): () => Promise<boolean> {
  return () => keycloak.init({
    config: {
      url: 'https://login.star-head.de/auth',
      realm: 'starhead',
      clientId: `${environment.stage}`,
    },
    initOptions: {
      onLoad: 'check-sso',
      flow: 'standard',
      checkLoginIframe: true,
      refreshToken: 'refresh',
      silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
    },
    loadUserProfileAtStartUp: true,
    enableBearerInterceptor: true,
    bearerPrefix: 'Bearer',
    bearerExcludedUrls: [
      'main.js'
    ],
  });
}
