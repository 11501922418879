import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'scCurrency'
})
export class ScCurrencyPipe implements PipeTransform {

  transform(value: number, arg0?: unknown): unknown {
    const lang = navigator.language;
    if(value === null || value === undefined) {
      return '0 aUEC';
    }
    value = +value.toFixed(0);
    const formatted = new Intl.NumberFormat(lang).format(value);
    if (arg0 === null || arg0 === undefined) {
      return `${formatted}`;
    }
    if (arg0 === 'aUEC'.toLowerCase()) {
      return `${formatted} aUEC`;
    }
    return `${formatted} UEC`;
  }

}
