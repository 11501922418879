import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoggerService } from "@starhead/service/logger.service";
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  baseUrl = environment.apiUrl + '/article';

  constructor(private http: HttpClient, private logger: LoggerService) { }

  getArticles() {
    const body = {
      "language": "de",
      "state": "Published",
      "page": 1,
      "pageSize": 5
    };

    return this.http.post(this.baseUrl + '/filter', body);
  }

  getArticleById(id: string) {
    return this.http.get<any>(this.baseUrl + '/' + id);
  }
}
