<div class="content-wrapper">
    <sh-content-container *ngIf="article" [heading]="article.title">
        <div class="content">
            <img
                [src]="article.imageUrl"
                alt="wingman_ai_and_starhead"
                class="content-image" />
            <p [innerHTML]="htmlContent"></p>
            <p>Autor: {{article.author}}</p>
            <p>Veröffentlicht: {{article.publishedAt | date}}</p>
        </div>
    </sh-content-container>
</div>