import { Injectable } from '@angular/core';
import { CelestialObject } from '@starhead/entities/celestial-objects/celestial-object';
import { Group } from '@starhead/core/interfaces/grouping/group';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { CelestialObjectType } from '@starhead/core/interfaces/celestial-objects/celestial-object-type';
import { CelestialObjectDistance } from '@starhead/entities/celestial-objects/celestial-object-distance';
import { ToastrService } from 'ngx-toastr';
import { CreateCelestialObjectDto } from '@starhead/core/interfaces/celestial-objects/create-celestial-object-dto';
import { LoggerService } from '@starhead/service/logger.service';
import { map } from 'rxjs/operators';
import { Location } from '@starhead/entities/location';
import { StarSystem } from '@starhead/entities/star-system';

@Injectable({
    providedIn: 'root',
})
export class CelestialObjectService {
    baseUrl = environment.apiUrl + '/celestialObject';
    celestialObjects: CelestialObject[] = [];
    paths: [{ id: number; path: string }] = [{ id: 1, path: 'Stanton' }];
    systemCache = new Map();

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private logger: LoggerService,
    ) {
        this.systemCache.set(1, 'Stanton');

        http.get<CelestialObject[]>(this.baseUrl).subscribe((result) => {
            this.celestialObjects = result;
            this.celestialObjects.map(async (co) => {
                await this.getCelestialPath(co);
            });
        });
    }

    getAllCelestialObjects(): Observable<CelestialObject[]> {
        return this.http.get<CelestialObject[]>(this.baseUrl);
    }

    getCelestialObjectTypes(): Observable<CelestialObjectType[]> {
        return this.http.get<CelestialObjectType[]>(this.baseUrl + '/type');
    }

    async getCelestialObjectByIdAsync(id: number): Promise<CelestialObject> {
        const result = this.http.get<CelestialObject>(this.baseUrl + '/' + id);
        return result.toPromise();
    }

    getCelestialObjectById(id: number): Observable<CelestialObject> {
        return this.http.get<CelestialObject>(this.baseUrl + '/' + id);
    }

    getCelestialObjectDistances(): Observable<CelestialObjectDistance[]> {
        return this.http.get<CelestialObjectDistance[]>(
            this.baseUrl + '/distance',
        );
    }

    async getCelestialObjectDistance(
        id: number,
    ): Promise<CelestialObjectDistance> {
        let result: CelestialObjectDistance;
        const distances = await this.getCelestialObjectDistances().toPromise();
        result = distances.find((dist) => dist.id === id);
        return result;
    }

    getAllStarSystems(): Observable<StarSystem[]> {
        return this.http.get<StarSystem[]>(environment.apiUrl + '/starsystem').pipe();
    }

    getStarSystemById(id: number): Observable<StarSystem> {
      return this.http.get<StarSystem>(environment.apiUrl + '/starsystem/' + id);
    }

    updateCelestialObject(celestialObject: CreateCelestialObjectDto): void {
        console.log('updateCelestailObject: ', celestialObject);
        this.http.put(this.baseUrl, celestialObject).subscribe(
            (response) => {
                console.log(response);
                this.toastr.success(
                    `CelestialObject ${celestialObject.name} saved.`,
                );
            },
            (error) => {
                console.log(
                    'failed to update CelestialObject: ' +
                        JSON.stringify(error),
                );
                this.toastr.error(`Failed to save ${celestialObject.name}.`);
            },
        );
    }

    updateCelestialObjectDistance(
        distance: CelestialObjectDistance,
    ): Observable<void> {
        return this.http.put<void>(this.baseUrl + '/distance', distance);
    }

    groupBySystem(
        source: CelestialObject[]
    ): Group<Group<CelestialObject>>[] {
        const groupedSystem: Group<Group<CelestialObject>>[] = [];
        this.getAllStarSystems().subscribe((systems) => {
          systems.forEach((system) => {
            if (system.isDisabled) {
              return;
            }
            const systemGroup: Group<Group<CelestialObject>> = {
              name: system.name,
              items: [],
            };
            const planets = source.filter((p) => p.system.id === system.id && p.parentId == null);
            planets.forEach((planet) => {
              const planetGroup: Group<CelestialObject> = {
                name: planet.name,
                items: [planet],
              };

              const children = source.filter((c) => c.parentId === planet.id);
              children.forEach((child) => {
                planetGroup.items.push(child);
              });
              planetGroup.items.sort((p1, p2) => {
                if (p1.type === 'Planet' && p2.type === 'Moon') {
                  return -1;
                }
                if (p1.type === 'Moon' && p2.type === 'Planet') {
                  return 1;
                }
                if (
                  (p1.type === 'Planet' || p1.type === 'Moon') &&
                  p2.type === 'LagrangePoint'
                ) {
                  return -1;
                }
                if (p1.type === 'LagrangePoint') {
                  return 1;
                }

                return 0;
              });
              systemGroup.items.push(planetGroup);
            });

            groupedSystem.push(systemGroup);
          });
        })

        return groupedSystem;
    }

    getCelestialObjectPath(celestialObject: CelestialObject): string {
        const path = this.paths.find((p) => p.id === celestialObject.id);
        return path?.path || '';
    }

    async getCelestialPath(co: CelestialObject): Promise<void> {
        let currentPlanet: CelestialObject = null;
        let currentMoon: CelestialObject = null;
        let system: StarSystem = null;
        let path: string;

        if (co?.type === CelestialObjectType[CelestialObjectType.Moon]) {
            currentMoon = co;
            currentPlanet = this.celestialObjects.find(
                (c) => c.id === currentMoon.parentId,
            );
            const systemName = this.systemCache.get(currentPlanet.system.id);
            if (!systemName) {
                await this.getStarSystemById(currentPlanet.system.id)
                    .toPromise()
                    .then((result) => {
                        system = result;
                        path = `${system.name} | ${currentPlanet.name} | ${currentMoon.name}`;
                        this.paths.push({ id: co.id, path: path });
                        this.systemCache.set(system.id, system.name);
                    });
            } else {
                path = `${systemName} | ${currentPlanet.name} | ${currentMoon.name}`;
                this.paths.push({ id: co.id, path: path });
            }
        } else if (
            co
        ) {
            const systemName = this.systemCache.get(co.system.id);
            if (!systemName) {
                await this.getStarSystemById(co.system.id)
                    .toPromise()
                    .then((result) => {
                        system = result;
                        path = `${system.name} | ${co.name}`;
                        this.paths.push({ id: co.id, path });
                        this.systemCache.set(system.id, system.name);
                    });
            } else {
                path = `${systemName} | ${co.name}`;
                this.paths.push({ id: co.id, path });
            }
        } else {
            this.paths.push({ id: co.id, path: co.name });
        }
    }
}
