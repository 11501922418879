import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { CommodityTradeType } from "@starhead/core/interfaces/enums/commodity-trade-type";

@Component({
  selector: 'sh-shop-item-price-input',
  templateUrl: './shop-item-price-input.component.html',
  styleUrls: ['./shop-item-price-input.component.scss']
})
export class ShopItemPriceInputComponent implements OnInit {
  @Input() itemPrice: number;
  @Input() currentTradeType: CommodityTradeType;
  @Output() itemPriceChange = new EventEmitter<number>();
  @Output() currentTradeTypeChange = new EventEmitter<CommodityTradeType>();
  readonly TradeTypes: typeof CommodityTradeType = CommodityTradeType;

  constructor() { }

  ngOnInit(): void {
  }

  setSelectedTradeType(tradeType: CommodityTradeType) {
    this.currentTradeType = tradeType;
    this.currentTradeTypeChange.emit(tradeType);
  }

  handleInputChange(e) {
    this.itemPrice = e.target.valueAsNumber;
    this.itemPriceChange.emit(e.target.value);
  }

}
