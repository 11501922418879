import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShDropdownConfig {
  autoClose = true;
  insideClick = false;
  isAnimated = false;
}
