<div class="sh-content-container" [class.interactive]="isInteractive">
    <div *ngIf="hasTitle()" class="text-center sh-cc-heading sh-text-title p-2">
        <span [innerHTML]="heading"></span>
        <div class="sh-top-corners"></div>
        <div class="sh-bottom-corners"></div>
    </div>
    <div class="sh-content-container-content">
        <ng-content></ng-content>
    </div>
</div>
