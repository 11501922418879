import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DialogRef } from "@angular/cdk/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ShopUpdateDto } from "@starhead/core/dtos/shop-update-dto";
import { ShopType } from "@starhead/core/interfaces/trading/shop-type";
import { ShopService } from "@starhead/service/tradingServices/shop.service";
import { ToastrService } from "ngx-toastr";
import { LoggerService } from "@starhead/service/logger.service";

@Component({
  selector: 'sh-shop-create-form',
  templateUrl: './shop-create-form.component.html',
  styleUrls: ['./shop-create-form.component.scss']
})
export class ShopCreateFormComponent implements OnInit {
  shopForm: FormGroup;
  shopTypes: ShopType[];

  constructor(private fb: FormBuilder,
              private dialogRef: DialogRef<ShopCreateFormComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ShopUpdateDto,
              private shopService: ShopService,
              private toastr: ToastrService,
              private logger: LoggerService) {
    this.logger.debugWithId('shop', ShopCreateFormComponent.name, 'constuctor', data);
  }

  ngOnInit(): void {
    this.logger.debugWithId('Shop', ShopCreateFormComponent.name, this.ngOnInit.name, this.data);
    this.initForm();
    this.loadShopTypes();
  }

  onCancel() {
    this.dialogRef.close();
  }

  initForm() {
    this.shopForm = this.fb.group({
      name: ['', { }, Validators.required],
      parentLocationId: [this.data.parentLocationId, { disabled: true }],
      thumbnailUrl: ['', { }, Validators.required],
      description: ['', { }, Validators.required],
      types: ['', { }, Validators.required]
    });

  }

  loadShopTypes() {
    this.shopService.getShopTypes().subscribe(result => {
      this.shopTypes = result;
    })
  }

}
