import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject, take } from "rxjs";
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Organization} from '@starhead/core/entities/organization';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ManufacturerService {
  private baseUrl = environment.apiUrl + '/organization';
  private manufacturerSource = new ReplaySubject<Organization[]>(1);
  manufacturer$ = this.manufacturerSource.asObservable();

  constructor(private http: HttpClient) { }

  // eslint-disable-next-line max-len
  getAllManufaturers(filterVehicleManufacturers: boolean = false, filterComponentManufacturers: boolean = false): Observable<Organization[]> {
    this.http.get<Organization[]>(
      `${this.baseUrl}?filterVehicleManufacturers=${filterVehicleManufacturers}&filterComponentManufacturers=${filterComponentManufacturers}`)
      .subscribe(value => {
        this.manufacturerSource.next(value);
      });

    return this.manufacturer$;
  }

  getManufacturerById(id: number): Organization {
    return null
  }
}
