import {
    AfterViewInit,
    Component,
    HostListener,
    Input,
    OnInit,
} from '@angular/core';
import { LoggerService } from '@starhead/service/logger.service';

@Component({
    selector: 'sh-content-container',
    templateUrl: './content-container.component.html',
    styleUrls: ['./content-container.component.scss'],
})
export class ContentContainerComponent implements OnInit, AfterViewInit {
    @Input() heading: string = '';
    @Input() width: number = 300;
    @Input() height: number = 600;
    @Input() isInteractive: boolean = false;

    constructor(private logger: LoggerService) {}

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    ngOnInit(): void {}

    ngAfterViewInit() {}

    hasTitle(): boolean {
        return this.heading && this.heading !== '';
    }
}
