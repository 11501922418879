import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {PrivacyPolicyComponent} from '@starhead/core/components/privacy-policy/privacy-policy.component';
import {ImpressumComponent} from '@starhead/core/components/impressum/impressum.component';
import { AuthGuard } from "@starhead/core/guards/auth.guard";
import {TestBlockerGuard} from "@starhead/core/guards/test-blocker.guard";
import { PriceItemToolComponent } from "./price-item-tool/price-item-tool.component";
import { NewsDetailComponent } from './news/news-detail.component';

export const routes: Routes = [
  {
    path: '', component: HomeComponent,
    data: {title: 'Star-Head'},
    runGuardsAndResolvers: 'always',
    canActivate: [TestBlockerGuard],
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [TestBlockerGuard],
    children: [
      {
        path: 'trading',
        loadChildren: () => {
          return import('@starhead/trading/trading.module').then(m => m.TradingModule);
        }
      },
      {
        path: 'database',
        //canActivate: [AuthGuard],
        //data: {roles: ['admin', 'moderator']},
        loadChildren: () => {
          return import('@starhead/database/database.module').then(m => m.DatabaseModule);
        }
      },
      {
        path: 'news/:id',
        component: NewsDetailComponent
      },
      {
        path: 'admin',
        runGuardsAndResolvers: "always",
        canActivate: [AuthGuard],
        data: {roles: ['admin', 'moderator']},
        loadChildren: () => {
          return import('./administration/administration.module').then(m => m.AdministrationModule);
        }
      }
    ]
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'impressum',
    component: ImpressumComponent
  },
  {
    path: 'price-tool',
    component: PriceItemToolComponent,
    runGuardsAndResolvers: "always",
    data: {loginRequired: true},
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const appRoutingComponents = [
  HomeComponent,
  NewsDetailComponent
];
