import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: 'sh-shop-item-actions',
  templateUrl: './shop-item-actions.component.html',
  styleUrls: ['./shop-item-actions.component.scss']
})
export class ShopItemActionsComponent implements OnInit {

  @Input() saveVisible: boolean = false;
  @Input() editVisible: boolean = false;
  @Input() cancelVisible: boolean = false;
  @Input() deleteVisible: boolean = false;

  @Output() saveAction = new EventEmitter();
  @Output() editAction = new EventEmitter();
  @Output() cancelAction = new EventEmitter();
  @Output() deleteAction = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onSaveAction() {
    this.saveAction.emit();
  }

  onCancelAction() {
    this.cancelAction.emit();
  }

  onEditAction() {
    this.editAction.emit();
  }

  onDeleteAction() {
    this.deleteAction.emit();
  }

}
