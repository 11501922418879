<div class="container">
    <div class="col-md-6">
      <h3>Bild wählen</h3>
      <div ng2FileDrop
           [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
           (fileOver)="fileOverBase($event)"
           [uploader]="uploader"
           (onFileDrop)="dropped($event)"
           class="well my-drop-zone">
        <span>Bild hierhin ziehen</span>
      </div>
      <input type="file" ng2FileSelect [uploader]="uploader" (onFileSelected)="dropped($event)"/>
    </div>
    <div class="col-md-12 mt-3" style="margin-bottom: 40px">
      <h3>Upload queue</h3>
      <p>Warteschlange: {{ uploader?.queue?.length }}</p>
      <table class="table">
        <thead class="sh-text-light">
        <tr>
          <th width="50%">Name</th>
          <th>Größe</th>
          <th>Fortschritt</th>
          <th>Status</th>
          <th>Aktionen</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of uploader.queue" class="sh-text-light">
          <td><strong>{{ item?.file?.name }}</strong></td>
          <td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
          <td *ngIf="uploader.options.isHTML5">
            <div class="progress" style="margin-bottom: 0;">
              <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': item.progress + '%' }"></div>
            </div>
          </td>
          <td class="text-center">
            <span *ngIf="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
            <span *ngIf="item.isCancel"><i class="glyphicon glyphicon-ban-circle"></i></span>
            <span *ngIf="item.isError"><i class="glyphicon glyphicon-remove"></i></span>
          </td>
          <td nowrap>
            <button type="button" class="btn btn-success btn-xs"
                    (click)="uploadFile()" [disabled]="item.isReady || item.isUploading || item.isSuccess">
              <span class="glyphicon glyphicon-upload"></span> Upload
            </button>
            <button type="button" class="btn btn-warning btn-xs"
                    (click)="item.cancel()" [disabled]="!item.isUploading">
              <span class="glyphicon glyphicon-ban-circle"></span> Abbrechen
            </button>
            <button type="button" class="btn btn-danger btn-xs"
                    (click)="item.remove()">
              <span class="glyphicon glyphicon-trash"></span> Entfernen
            </button>
          </td>
        </tr>
        </tbody>
      </table>

<!--      <div>-->
<!--        <div>-->
<!--          Queue progress:-->
<!--          <div class="progress" style="">-->
<!--            <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <button type="button" class="btn btn-success btn-s"-->
<!--                (click)="uploader.uploadAll()" [disabled]="!uploader.getNotUploadedItems().length">-->
<!--          <span class="glyphicon glyphicon-upload"></span> Upload all-->
<!--        </button>-->
<!--        <button type="button" class="btn btn-warning btn-s"-->
<!--                (click)="uploader.cancelAll()" [disabled]="!uploader.isUploading">-->
<!--          <span class="glyphicon glyphicon-ban-circle"></span> Cancel all-->
<!--        </button>-->
<!--        <button type="button" class="btn btn-danger btn-s"-->
<!--                (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length">-->
<!--          <span class="glyphicon glyphicon-trash"></span> Remove all-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
</div>
