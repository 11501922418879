<div class="sh-form--login login-form-container">
  <h4 *ngIf="isLoggedIn; else elseBlock">Hallo {{userProfile?.username | titlecase}}</h4>
  <ng-template #elseBlock>
    <h4>Du bist nicht angemeldet</h4>
  </ng-template>
  <div *ngIf="isLoggedIn">
    <span>Email: {{userProfile?.email}}</span><br>
    <span>Vorname: {{userProfile?.firstName}}</span><br>
    <span>Nachname: {{userProfile?.lastName}}</span><br>

    <button class="sh-btn sh-btn-outline-accent" (click)="onEditProfileClick()">Profil bearbeiten</button>
    <br>
    <button class="sh-btn sh-btn-outline-danger" (click)="onLogoutClick()">Abmelden</button>
  </div>
  <form *ngIf="!isLoggedIn" [formGroup]="loginForm">
    <div class="sh-btn-group" role="group">
      <button type="submit" class="sh-btn sh-btn-outline-accent" (click)="onSignupClick(loginForm.value)"
              i18n="@@gen-lbl-signin">Anmelden
      </button>
      <button type="button" class="sh-btn sh-btn-outline-info" i18n="@@registerBtnText"
              (click)="register()">Registrieren
      </button>
    </div>
  </form>
</div>
