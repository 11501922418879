<div style="margin: 16px;" #container>
  <mat-grid-list [cols]="container.offsetWidth / 350" rowHeight="700px" gutterSize="5">
    <mat-grid-tile *ngFor="let priceEntry of priceTransferDtos; let i = index">
      <sh-shop-item-price
        [priceEntry]="priceEntry"
        (priceEntryChange)="patchPriceEntry($event, priceEntry, i)"
        (removeEntry)="onRemoveEntry(i)"
        >
      </sh-shop-item-price>
    </mat-grid-tile>
    <mat-grid-tile>
      <button
        class="sh-btn sh-btn-success sh-btn-round"
        (click)="addPriceEntry()"
        matTooltip="Neue Preiskarte hinzufügen"
        matTooltipHideDelay="100"
        matTooltipPosition="below"
        matTooltipClass="sh-add-price-tooltip"
      >
        <mat-icon>add_task</mat-icon>
      </button>

      <button class="sh-btn sh-btn-info mt-5 mb-5 ml-10" (click)="savePrices()" i18n="@@save">Speichern</button>
    </mat-grid-tile>
  </mat-grid-list>
</div>

