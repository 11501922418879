class Permission {
    id: string;
    token: string;
}

export class StarHeadPermissions {
    private permissionList: Permission[] = [
        {
            id: 'Patreon',
            token: '50702891-D2B4-4A4B-A7D1-F7E4C9E5D8C8',
        },
        {
            id: 'Moderator',
            token: 'CDFB4C3B-60F7-4EA5-B7CD-05E5B9343E02',
        },
        {
            id: 'Subscriber',
            token: '86DA3FD3-12FE-4FBB-98F1-6FCB4EDE9635',
        },
        {
            id: 'StarHeadAdmin',
            token: '4FE7B5AD-ECAE-4C2C-88A6-ABE7B0382172',
        },
    ];

    private testPermissionList: Permission[] = [
        {
            id: 'StarHeadAdmin',
            token: '4FE7B5AD-ECAE-4C2C-88A6-ABE7B0382172',
        },
    ];
    private ids: string[] = [];

    constructor() {}

    testPermission(token: string, id: string): boolean {
        const permission = this.permissionList.find((p) => p.id === id);
        return permission && permission.token === token;
    }
}
