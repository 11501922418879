import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Group} from '@starhead/core/interfaces/grouping/group';
import {Ship} from '@starhead/core/entities/vehicle/ship';

@Component({
  selector: 'sh-groupe-item',
  templateUrl: './groupe-item.component.html',
  styleUrls: ['./groupe-item.component.scss']
})
export class GroupeItemComponent implements OnInit {
  @Input() group: Group<any>[];
  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output('selected') selectedItem = new EventEmitter<any>();
  @Input() PropertyName: string;

  constructor() { }

  ngOnInit(): void {
    console.log('group item component: ' + this.group);
  }

}
