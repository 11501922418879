<mat-card class="sh-m-w-__300">
  <mat-card-header class="sh-bg-primary">
    <button
      mat-icon-button><mat-icon fontIcon="delete" (click)="onRemoveHardpoint(hardpoint)"></mat-icon></button>
    <mat-card-title>
      <h2>{{hardpoint?.hardpointType.name}}</h2>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="px-2">
    <form [formGroup]="form">
      <mat-form-field appearance="standard">
        <mat-label>Anzahl: </mat-label>
        <input matInput placeholder="Anzahl" formControlName="quantity">
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Size: </mat-label>
        <mat-select formControlName="hardpointSizeId">
          <mat-option *ngFor="let size of hardpointSizes" [value]="size.id">{{size?.name}}</mat-option>
        </mat-select><br>
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Type: </mat-label>
        <mat-select formControlName="hardpointTypeId">
          <mat-option *ngFor="let type of hardpointTypes" [value]="type.id">{{type.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-card-content>
</mat-card>
