import { Component, Input, OnInit } from "@angular/core";
import { AccountService } from "@starhead/service/account.service";
import { MatSidenav } from "@angular/material/sidenav";

@Component({
  selector: 'sh-sidebar-admin-access',
  templateUrl: './sidebar-admin-access.component.html',
  styleUrls: ['./sidebar-admin-access.component.scss']
})
export class SidebarAdminAccessComponent implements OnInit {
  @Input() sidenav: MatSidenav;
  isUserAdmin: boolean = false;

  constructor(private accountService: AccountService) { }

  ngOnInit(): void {
    this.accountService.getIsUserAdminOrModerator().subscribe(res => this.isUserAdmin = res  );
  }

}
