<div class="d-flex mt-1" cdkOverlayOrigin #qdSelectionOverlayOrigin>
  <div class="d-flex w-100" >
    <span
      style="display: inline-block; width: 10px; height: 100%; border-bottom-left-radius: 5px;
      border-top-left-radius: 5px; background-color: #00daf1"></span>
    <button mat-flat-button class="w-100 sh-component-selection__button sh-bg-dark-gray" (click)="toggleQdSelection()">
      <div class="d-flex flex-column">
        <div class="d-flex align-items-center">
          <span>
            <span class="component-size text-info">Size {{componentSize?.name}}</span>
            <span class="mx-1"></span>
            <span class="mx-1" >{{selectedComponent?.name || 'Leer'}}</span>
          </span>
        </div>
      </div>
    </button>
    <span
      style="display: inline-block; width: 10px; height: 100%; border-bottom-right-radius: 5px;
      border-top-right-radius: 5px; background-color: #00daf1"></span>
  </div>
  <div class="my-1"></div>
  <div class="d-flex"></div>
</div>
<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="qdSelectionOverlayOrigin"
             [cdkConnectedOverlayOpen]="qdSelectionOpen">
  <sh-quantum-drive-selection [quantumDrives]="vehicleComponents" [hardpointSize]="componentSize"
                              (quantumDriveSelected)="setSelectedComponent($event)"
  ></sh-quantum-drive-selection>
</ng-template>
