<div class="sh-shop-entry">
  <div class="sh-shop-entry--header">
    <h3 class="sh-shop-entry__title">{{data.name}}</h3>
  </div>
  <div class="sh-shop-entry__content">
    <form [formGroup]="shopForm" class="sh-shop-entry__form">
      <mat-form-field appearance="legacy">
        <mat-label>Shopname</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>
      <mat-form-field appearance="legacy">
        <mat-label>Beschreibung</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>
      <mat-form-field appearance="legacy">
        <mat-label>Standort</mat-label>
        <mat-select formControlName="parentLocationId">
          <mat-option [value]="data.parentLocationId">{{data.parentLocationId}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="legacy">
        <mat-label>Shopart</mat-label>
        <mat-select formControlName="types" multiple>
          <mat-option *ngFor="let shopType of shopTypes" [value]="shopType.id">{{shopType.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <div class="sh-shop-entry__image-container">
      <img class="sh-shop-entry__image" [src]="'/assets/images/vehicle_default.png'">
      <div class="sh-shop-entry--actions">
        <div>
          <button mat-icon-button [disabled]="!shopForm.dirty" [mat-dialog-close]="shopForm.getRawValue()">Speichern
            <mat-icon>save</mat-icon></button>
          <button mat-icon-button [disabled]="!shopForm.dirty" (click)="onCancel()">Abbrechen
            <mat-icon>cancel</mat-icon></button>
        </div>
      </div>
    </div>
  </div>
</div>


