import { Inject, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, DatePipe, registerLocaleData } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ShPipes } from '@starhead/core/pipes';
import localeDe from '@angular/common/locales/de';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ServerStatusService } from '@starhead/service/server-status.service';
import { CommodityListComponent } from './components/commodity-list/commodity-list.component';
import { CommodityListItemComponent } from './components/commodity-list/commodity-list-item/commodity-list-item.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { GroupedDropdownComponent } from './components/grouped-dropdown/grouped-dropdown.component';
import { GroupeItemComponent } from './components/grouped-dropdown/groupe-item/groupe-item.component';
import { NavMenuDirective } from './directives/nav-menu.directive';
import {
  MenuItemComponent,
  MenuTitleDirective,
} from './components/menu-item/menu-item.component';
import { FooterComponent } from '@starhead/core/components/footer/footer.component';
import { TopNavComponent } from '@starhead/core/components/navigation/top-nav/top-nav.component';
import { SecondaryNavComponent } from '@starhead/core/components/navigation/secondary-nav/secondary-nav.component';
import { ServerStatusComponent } from '@starhead/core/components/navigation/top-nav/server-status/server-status.component';
import { RouterModule } from '@angular/router';
import { ContentContainerComponent } from './components/content-container/content-container.component';
import { DropdownDirective } from './directives/dropdown.directive';
import { ShDropdownContainerComponent } from '@starhead/core/components/dropdown/sh-dropdown-container.component';
import { ToolsNavBarComponent } from '@starhead/core/components/navigation/tools-nav-bar/tools-nav-bar.component';
import { QuestFormComponent } from './components/quest-form/quest-form.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ImpressumComponent } from './components/impressum/impressum.component';
import { CtrlValidatorDirective } from './directives/ctrl-validator.directive';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MatRippleModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SidebarAccountComponent } from './components/sidebar-account/sidebar-account.component';
import { TokenInterceptor } from '@starhead/core/interceptors/token.interceptor';
import { SidebarAdminAccessComponent } from './components/admin/sidebar-admin-access/sidebar-admin-access.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ToastrModule } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { FileUploadModule } from "ng2-file-upload";
import { ShopDetailsComponent } from './components/shops/shop-details/shop-details.component';
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { ShopItemPriceComponent } from './components/shops/shop-item-price/shop-item-price.component';
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { RangeSliderComponent } from './components/range-slider/range-slider.component';
import { DecimalConvertDirective } from './directives/decimal-convert.directive';
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { SpinnerComponent } from './spinner/spinner.component';
import { LoadingInterceptor } from "@starhead/core/interceptors/loading.interceptor";
import { NgxSpinnerModule } from "ngx-spinner";
import { GridListComponent } from './components/lists/grid-list/grid-list.component';
import { GridListTileComponent } from './components/lists/grid-list/grid-list-tile/grid-list-tile.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CookieService} from "@starhead/service/cookie.service";
import { LockedModalComponent } from './modals/locked-modal/locked-modal.component';
import { StarHeadPermissions } from "@starhead/core/Utility/star-head-permissions";
import { MaterialModule } from "../material/material.module";
import { CdkMenuNavbarComponent } from './components/navigation/cdk-menu-navbar/cdk-menu-navbar.component';
import { ShipCardComponent } from './components/ship-card/ship-card.component';
import { QuantumDriveSelectionComponent } from './components/vehicles/quantum-drive-selection/quantum-drive-selection.component';
import { VehicleComponentSelectorComponent } from './components/vehicles/vehicle-component-selector/vehicle-component-selector.component';
import { HardpointCardComponent } from './components/vehicles/hardpoint-card/hardpoint-card.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AdminShopItemComponent } from './components/admin/shops/admin-shop-item/admin-shop-item.component';
import { ShopItemHeaderComponent } from './components/shops/shop-item-header/shop-item-header.component';
import { ShopItemPriceInputComponent } from './components/shops/shop-item-price-input/shop-item-price-input.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { ShopItemActionsComponent } from "@starhead/components/shops/shop-item-actions/shop-item-actions.component";
import { ShopCreateFormComponent } from './components/shops/shop-create-form/shop-create-form.component';
import { AdminCreateShopItemComponent } from './components/admin/shops/admin-create-shop-item/admin-create-shop-item.component';

const Modules = [
  CommonModule,
  HttpClientModule,
  BsDropdownModule,
  ReactiveFormsModule,
  RouterModule,
  FormsModule,
  MatRippleModule,
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatIconModule,
  MatButtonModule,
  LayoutModule,
  MatToolbarModule,
  MatSidenavModule,
  MatListModule,
  MatExpansionModule,
  MatTableModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatDialogModule,
  FileUploadModule,
  NgxChartsModule,
  MatAutocompleteModule,
  NgxSpinnerModule,
  MatTooltipModule,
  MaterialModule,
  FlexLayoutModule
];

const coreComponents = [
  CommodityListComponent,
  CommodityListItemComponent,
  GroupedDropdownComponent,
  GroupeItemComponent,
  FooterComponent,
  TopNavComponent,
  SecondaryNavComponent,
  ServerStatusComponent,
  ShDropdownContainerComponent,
  ImageUploaderComponent,
  RangeSliderComponent,
  TooltipDirective,
  ShopItemActionsComponent,
  ShopCreateFormComponent,
  SpinnerComponent,
  GridListComponent,
  GridListTileComponent,
  FeedbackComponent,
  LockedModalComponent,
  CdkMenuNavbarComponent,
  ShipCardComponent,
  QuantumDriveSelectionComponent,
  VehicleComponentSelectorComponent,
  HardpointCardComponent,
  RangeSliderComponent,
  AdminShopItemComponent,
  ShopItemHeaderComponent,
  ShopItemPriceInputComponent,
  SidebarAccountComponent,
  SidebarAdminAccessComponent,
  ShopDetailsComponent,
  ShopItemPriceComponent,
  ToolsNavBarComponent,
  QuestFormComponent,
  PrivacyPolicyComponent,
  ImpressumComponent,
  ContentContainerComponent,
  MenuItemComponent,
  AdminCreateShopItemComponent
];

@NgModule({
  declarations: [
    ...ShPipes,
    ...coreComponents,
    NavMenuDirective,
    MenuTitleDirective,
    DropdownDirective,
    CtrlValidatorDirective,
    DecimalConvertDirective,
    TooltipDirective,
  ],
  imports: [
    ...Modules,
    ModalModule.forRoot(),
    MatButtonModule,
    MatMenuModule,
    MatSidenavModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    MatButtonToggleModule,
    TooltipModule,
  ],
  providers: [
    Title,
    ServerStatusService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    {provide: MAT_DIALOG_DATA, useValue: {}},
    DatePipe,
    CookieService,
    StarHeadPermissions,
  ],
  exports: [
    ...Modules,
    ...ShPipes,
    ...coreComponents,
    NavMenuDirective,
    MenuTitleDirective,
    ContentContainerComponent,
    ToolsNavBarComponent,
    QuestFormComponent,
    CtrlValidatorDirective,
    MenuItemComponent,
    ToastrModule,
    ShopItemPriceComponent,
    RangeSliderComponent,
    CdkMenuNavbarComponent,
    ShipCardComponent,
    QuantumDriveSelectionComponent,
    VehicleComponentSelectorComponent,
    HardpointCardComponent,
    SidebarAccountComponent,
    SidebarAdminAccessComponent,
    DecimalConvertDirective,
    RangeSliderComponent,
    AdminShopItemComponent,
    ShopItemHeaderComponent,
    TooltipDirective
  ]
})
export class CoreModule {
  constructor(@Inject(LOCALE_ID) locale: string) {
    registerLocaleData(localeDe);
  }
}

