import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from 'rxjs';
import { StarHeadPermissions } from "@starhead/core/Utility/star-head-permissions";
import { environment } from "../../environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { LockedModalComponent } from "@starhead/core/modals/locked-modal/locked-modal.component";
import { CookieService } from "@starhead/service/cookie.service";

@Injectable({
  providedIn: 'root'
})
export class TestBlockerGuard implements CanActivate {
  constructor(private permissions: StarHeadPermissions, public dialog: MatDialog, private cookieService: CookieService, private readonly router: Router) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    /* if(!environment.isTestLocked) {
      return true;
    } */

    if (location.hostname === 'localhost') {
      return true;
    }

    const hostname = window.location.hostname;

    if((hostname === 'release.star-head.de' || hostname === 'test.star-head.de' || location.hostname === 'localhost') ) {//&& (location.hostname !== 'localhost') {
      console.log(hostname);

      const cookie = this.cookieService.getCookie('STARHEAD_TEST_ACCESS').split(',');

      let perm = {id: cookie[1], token: cookie[0]};
      let access = this.permissions.testPermission(perm.token, perm.id);

      if(access) {
        return true;
      }

      perm = {id: '', token: ''};
      const params = route.queryParams;
      console.log(params);
      perm.id = params.id;
      perm.token = params.token;
      console.log(perm);

      access = this.permissions.testPermission(perm.token, perm.id);
      if(!access) {
        const dialogRef = this.dialog.open(LockedModalComponent, {
          width: '500px',
        });
        dialogRef.disableClose = true;
      } else {
        this.cookieService.setCookie({
          name: 'STARHEAD_TEST_ACCESS',
          value: [perm.token, perm.id],
          expireDays: 14,
        });
      }
      return access;
    }
      return true;
  }

}
