import {animate, AnimationMetadata, style} from '@angular/animations';

export const DROPDOWN_ANIMATION_TIMING = '230ms cubic-bezier(0, 0, 0.2, 1)';

export const shDropdownAnimation: AnimationMetadata[] = [
  style({height: 0, overflow: 'hidden'}),
  animate(
    DROPDOWN_ANIMATION_TIMING,
    style({height: '*', overflow: 'hidden'})
  )
];
