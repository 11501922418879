<div class="d-flex align-items-center text-muted pt-3 px-2">
  <div class="mat-title">Quantumdrive</div>
  <span class="mx-1"></span>
  <mat-form-field appearance="fill" class="sh-w100">
    <mat-label>Filter</mat-label>
    <input matInput>
  </mat-form-field>
</div>
<div class="table-bordered sh-component-table" style="width: 400px;">
  <table class="sh-bg-dark-gray sh-h250px sh-w100">
    <thead role="rowgorup">
      <tr role="row">
        <th role="columnheader">Size</th>
        <th role="columnheader">Name</th>
      </tr>
    </thead>
    <tbody >
      <tr *ngFor="let component of quantumDrives" (click)="selectQuantumDrive(component)"
          class="sh-component-table-row">
        <td>{{component?.hardpointSize.name}}</td>
        <td>{{component?.name}}</td>
      </tr>
    </tbody>
  </table>
</div>
