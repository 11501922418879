import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { VehicleHardpoint } from "@starhead/entities/vehicle/vehicle-hardpoint";
import { QuantumDrive } from "@starhead/entities/quantum-drive";
import { VehicleComponent } from "@starhead/core/interfaces/vehicles/components/vehicle-component";
import { HardpointSize } from "@starhead/core/interfaces/vehicles/hardpoints/hardpoint-size";
import { LoggerService } from "@starhead/service/logger.service";

@Component({
  selector: 'sh-quantum-drive-selection',
  templateUrl: './quantum-drive-selection.component.html',
  styleUrls: ['./quantum-drive-selection.component.scss'],
})
export class QuantumDriveSelectionComponent implements OnInit, AfterViewInit {
  @Input() componentSize: VehicleHardpoint;
  @Input() quantumDrives: VehicleComponent[];
  @Input() hardpointSize: HardpointSize;
  @Input() vehicleComponent?: VehicleComponent;
  @Output() quantumDriveSelected = new EventEmitter<VehicleComponent>();

  constructor(private logger: LoggerService) {}

  ngOnInit(): void {
    this.logger.debugWithId('Initializing QuantumDrive-Selector', QuantumDriveSelectionComponent.name, this.ngOnInit.name, this.quantumDrives, this.hardpointSize);
  }

  ngAfterViewInit() {

  }

  selectQuantumDrive(component: VehicleComponent) {
    this.quantumDriveSelected.emit(component);
  }
}
