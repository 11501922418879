import {AfterContentInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {ManufacturerService} from '@starhead/service/manufacturer.service';
import {Position} from '@starhead/core/interfaces/position';
import {StarHeadService} from '@starhead/service/star-head.service';
import {Organization} from '@starhead/core/entities/organization';
import { AccountService } from "@starhead/service/account.service";
import { MatDialog } from "@angular/material/dialog";
import { FeedbackComponent } from "@starhead/components/feedback/feedback.component";

@Component({
  selector: 'sh-secondary-nav',
  templateUrl: './secondary-nav.component.html',
  styleUrls: ['./secondary-nav.component.scss']
})
export class SecondaryNavComponent implements OnInit, AfterContentInit {
  activeRoute: string;
  @ViewChild('newsItem') newsItem: ElementRef<HTMLLIElement>;
  @ViewChild('tradingItem') tradingItem: ElementRef;
  manufacturers: Organization[];
  pos: Position = {top: 0, left: 0, x: 0, y: 0 };
  isScrollDragging = false;

  // NavItems selected
  newsNavSelected = false;
  databaseNavSelected = false;
  toolsNavSelected = false;
  guidesNavSelected = false;
  communityNavSelected = false;

  constructor(private router: Router, private manufacturerServcice: ManufacturerService, private starHeadService: StarHeadService, public accountService: AccountService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.initData();
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.mapRoute(e.url.split('/')[1]);
      }
    });
    this.pos = {top: 0, left: 0, x: 0, y: 0 };
  }

  ngAfterContentInit(): void {
    this.isScrollDragging = false;
  }

  initData(): void {
    this.starHeadService.organizations$.subscribe(orgs => {
      this.manufacturers = orgs.filter(o => o.isVehicleManufacturer);
    });
  }

  mapRoute(route: string) {
    switch (route) {
      case 'trading':
        this.activeRoute = 'tools';
        break;
      case 'mining':
        this.activeRoute = 'tools';
        break;
      default:
        this.activeRoute = route;
        break;
    }
  }

  toggleSelectedNav(key: string): void {
    switch (key) {
      case 'mining':
      case 'trading':
        this.newsNavSelected = false;
        this.databaseNavSelected = false;
        this.toolsNavSelected = true;
        this.guidesNavSelected = false;
        this.communityNavSelected = false;
        break;
      default:
        this.toolsNavSelected = false;
    }
  }

  openFeedbackDialog(): void {
    const dialogRef = this.dialog.open(FeedbackComponent, {
      width: '400px',
      panelClass: 'sh-content-container'
    });
  }
}
