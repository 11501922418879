import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ShopItemPriceDto } from "@starhead/core/dtos/shop-item-price-dto";
import { ShopItemPriceEntry } from "@starhead/entities/shops/shop-item-price-entry";
import { ShopService } from "@starhead/service/tradingServices/shop.service";
import { LoggerService } from "@starhead/service/logger.service";
import { ShopItemTransferDto } from "@starhead/core/dtos/shop-item-transfer-dto";
import { error } from "protractor";
import { AdminShopsComponent } from "../../../../../app/administration/admin-shops/admin-shops.component";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'sh-admin-shop-item',
  templateUrl: './admin-shop-item.component.html',
  styleUrls: ['./admin-shop-item.component.scss']
})
export class AdminShopItemComponent implements OnInit, OnChanges {
  @Input() shopItemPrice: ShopItemPriceEntry;
  @Output() shopItemDeleted: EventEmitter<any> = new EventEmitter();
  @Output() shopItemCreated: EventEmitter<any> = new EventEmitter();

  shopItemToEdit: ShopItemPriceEntry;

  constructor(private shopService: ShopService, private logger: LoggerService, private toastr: ToastrService) {

  }

  ngOnInit(): void {
    let itemCopy = JSON.stringify(this.shopItemPrice)
    this.shopItemToEdit = JSON.parse(itemCopy);
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  saveShopItem() {
    const shopItemPriceDto: ShopItemPriceDto = {
      shopId: this.shopItemToEdit.shop.id,
      pricePerItem: this.shopItemToEdit.pricePerItem,
      commodityId: this.shopItemToEdit.commodity.id,
      maxInventoryCscu: this.shopItemToEdit.maxInventoryCscu,
      tradeType: this.shopItemToEdit.tradeType,
      refreshScuPerMinute: this.shopItemToEdit.refreshScuPerMinute,
    };

    const shopItemToUpdateDto: ShopItemTransferDto = {
      maxInventoryCscu: this.shopItemToEdit.maxInventoryCscu,
      commodityId: this.shopItemToEdit.commodity.id,
      shop: this.shopItemToEdit.shop,
      pricePerItem: this.shopItemToEdit.pricePerItem,
      tradeType: this.shopItemToEdit.tradeType,
      refreshScuPerMinute: this.shopItemToEdit.refreshScuPerMinute,
      location: this.shopItemToEdit.shop.parent,
      internalShopInventoryNodeId: ''
    };
    // this.shopService.addOrUpdateShopItem(shopItemToUpdateDto).subscribe(result => {
    //   this.logger.infoWithId('ShopItem updated.', AdminShopItemComponent.name, this.saveShopItem.name, result);
    // }, error => this.logger.errorWithId('Failed to update ShopItem', AdminShopItemComponent.name, this.saveShopItem.name, error));
    this.shopService.addOrUpdateShopItemPrice([shopItemPriceDto]).subscribe(result => {
      this.toastr.info(`Das ShopItem ${this.shopItemPrice.commodity.name} wurde im Shop ${this.shopItemPrice.shop.name} erstellt.`, 'ShopItem erstellt');
      this.shopItemCreated.emit(this.shopItemPrice.shop);
    });
  }

  resetShopItem() {
    let itemCopy = JSON.stringify(this.shopItemPrice)
    this.shopItemToEdit = JSON.parse(itemCopy);
  }

  updateShopItemPrice(price: number) {
    this.shopItemToEdit.maxInventoryCscu = price;
  }

  deleteShopItem() {
    this.shopService.deleteShopItem({shopId: this.shopItemPrice.shop.id, tradeType: this.shopItemPrice.tradeType, commodityId: this.shopItemPrice.commodity.id, internalShopInventoryNodeId: '', pricePerItem: this.shopItemPrice.pricePerItem, maxInventoryCscu: this.shopItemPrice.maxInventoryCscu, refreshCscuPerMinute: this.shopItemPrice.refreshScuPerMinute})
      .subscribe((result) => {
        this.logger.debugWithId('Löschen erfolgreich', AdminShopItemComponent.name, this.deleteShopItem.name, result);
        this.toastr.info(`Das ShopItem ${this.shopItemPrice.commodity.name} wurde aus dem Shop ${this.shopItemPrice.shop.name} gelöscht.`, 'ShopItem gelöscht');
        this.shopItemDeleted.emit(this.shopItemPrice.shop);
      });
  }

}
