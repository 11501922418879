import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FileUploader } from "ng2-file-upload";
import { environment } from "../../../environments/environment";
import { ToastrService } from "ngx-toastr";
import { AccountService } from "@starhead/service/account.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: 'sh-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnInit {
  uploader: FileUploader;
  @Input() hasBaseDropZoneOver: boolean;
  @Input() hasSecondDropZoneOver: boolean;
  @Input() uploadUrl: string;
  @Output() response = new EventEmitter();

  constructor(private toastr: ToastrService, private accountService: AccountService, private http: HttpClient) {
  }

  ngOnInit(): void {
    this.uploader = new FileUploader({
      url: this.uploadUrl,
      method: 'POST',
      isHTML5: true,
      removeAfterUpload: true,
      autoUpload: false,
    });

    this.hasBaseDropZoneOver = false;
    this.hasSecondDropZoneOver = false;

    this.uploader.response.subscribe(res => {
      this.response.emit(res);
    });
  }

  public fileOverBase(event: any): void {
    this.hasBaseDropZoneOver = event;
  }

  public fileOverSecondBase(event: any): void {
    this.hasSecondDropZoneOver = event;
  }

  dropped(files: any[]) {
    while (this.uploader.queue.length > 1) {
      this.uploader.queue.pop();
    }
  }

  setUploadUrl(url: string) {
    this.uploader.options.url = url;
  }

  public upload(id: number, uploadUrl: string): void {
    if(this.uploader.isUploading) return;
    if(this.uploader.queue.length < 1) return;
    this.uploader.options.url = uploadUrl;
    let item = this.uploader.queue[0]._file;
    let formdata: FormData = new FormData();
    formdata.append('image', item, item.name);
    this.http.post(this.uploadUrl, formdata).subscribe((response) => {
      console.log(`[IMAGEUPLOAD]::[RESPONSE] => ${response}`);
      if(this.uploader.isUploading) {
        this.uploader.progress = +response['loaded'] / +response['total'];
      }
    })
  }

  uploadFile() {
    if(this.uploader.isUploading) return;
    let item = this.uploader.queue[0]._file;
    let formFile: FormData = new FormData();
    formFile.append('file', item);

    this.http.post(this.uploadUrl, formFile, {reportProgress: true, observe: 'events'}).subscribe((response) => {
      console.log(`[IMAGEUPLOAD]::[RESPONSE] => ${JSON.stringify(response)}`);
      if (response.type > 2) {
        const fileItem = this.uploader.queue[0];
        this.uploader.removeFromQueue(fileItem);
        this.toastr.success('Das Bild wurde erfolgreich hoch geladen.');
      }
    }, error => {
      if(error?.status === 401) {
        this.toastr.error('Problem mit der Authentifizierung. Bitte erneut anmelden.');
      } else if( error?.stat >= 500) {
        this.toastr.error(error.message)
      } else {
        this.toastr.error('Fehler beim hochladen des Bildes. Bitte erneut versuchen.');
      }
    });
  }

}
