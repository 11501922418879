import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ArticleService } from '@starhead/core/services/article.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sh-news-detail',
    templateUrl: './news-detail.component.html',
    styleUrls: ['./news-detail.component.scss'],
})
export class NewsDetailComponent implements OnInit, OnDestroy {
    public htmlContent: SafeHtml;
    public article: any;
    private id: string | null = null;
    private routeSub: Subscription;

    constructor(
        private route: ActivatedRoute,
        private articelService: ArticleService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        this.routeSub = this.route.paramMap.subscribe((params) => {
            this.id = params.get('id');
            this.articelService.getArticleById(this.id).subscribe((res) => {
                console.log(res);
                this.article = res;
                this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(res.content);
            });
        });
    }

    ngOnDestroy(): void {
        if (this.routeSub) {
            this.routeSub.unsubscribe();
        }
    }
}
