<section>
  <form [formGroup]="shopItemForm">
    <mat-form-field appearance="standard">
      <mat-label>Shop</mat-label>
      <input matInput formControlName="shopName" disabled>
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Commodity</mat-label>
      <input type="text" placeholder="Commodity" matInput formControlName="commodity"
             [matAutocomplete]="commodityAutocomplete">
      <mat-autocomplete #commodityAutocomplete [displayWith]="commodityDisplayName">
        <mat-option *ngFor="let commodity of filteredCommodities | async"
                    [value]="commodity">{{commodity.name}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>TradeType</mat-label>
      <mat-select formControlName="tradeType">
        <mat-option *ngFor="let type of tradeTypes" [value]="type">{{type}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Max. Inventar</mat-label>
      <input matInput formControlName="maxInventoryCscu" type="number">
    </mat-form-field>
    <mat-form-field appearance="standard">
      <mat-label>Preis pro Item</mat-label>
      <input matInput formControlName="pricePerItem" type="number">
    </mat-form-field>
    <div>
      <button class="sh-btn sh-btn-secondary" [mat-dialog-close]="shopItemForm.value">Speichern</button>
      <button class="sh-btn sh-btn-danger" (click)="onCancel()">Abbrechen</button>
    </div>
  </form>
</section>
