import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { FeedbackComponent } from "@starhead/components/feedback/feedback.component";
import { MatDialog } from "@angular/material/dialog";
import { NavigationEnd, Router } from "@angular/router";
import { AccountService } from "@starhead/service/account.service";
import { Organization } from "@starhead/entities/organization";
import { StarHeadService } from "@starhead/service/star-head.service";
import { ConnectedPosition, ConnectionPositionPair } from "@angular/cdk/overlay";

@Component({
  selector: 'sh-cdk-menu-navbar',
  templateUrl: './cdk-menu-navbar.component.html',
  styleUrls: ['./cdk-menu-navbar.component.scss']
})
export class CdkMenuNavbarComponent implements OnInit {
  activeRoute = '';
  manufacturers: Organization[];
  cdkMenuPosition: ConnectedPosition[];
  subnavPosition: ConnectedPosition[];

  @Output() public sidenavToggle = new EventEmitter();

  @ViewChild('databaseMenu') databaseMenu: HTMLDivElement;

  constructor(public dialog: MatDialog, private router: Router, public accountService: AccountService, private starHeadService: StarHeadService, private ref: ElementRef) { }

  ngOnInit(): void {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.mapRoute(e.url.split('/')[1]);
      }
    });
    this.initVehicleManufacturers();
    this.cdkMenuPosition = [
      new ConnectionPositionPair(
        {originX: "start", originY: "top"},
        {overlayX: "start", overlayY: "top"},
        0,
        75
      )
    ];
  }

  initVehicleManufacturers() {
    this.starHeadService.organizations$.subscribe(orgs => {
      this.manufacturers = orgs.filter(o => o.isVehicleManufacturer);
    });
  }

  openFeedbackDialog(): void {
    const dialogRef = this.dialog.open(FeedbackComponent, {
      width: '400px',
    });
  }

  mapRoute(route: string) {
    switch (route) {
      case 'trading':
        this.activeRoute = 'tools';
        break;
      case 'mining':
        this.activeRoute = 'tools';
        break;
      default:
        this.activeRoute = route;
        break;
    }
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }
}
