<section fxHide.lt-md>
    <div class="w-100 sh-nav-menu-list navbar-nav sh-bg-primary">
        <div class="sh-navbar" cdkMenuBar cdkTargetMenuAim>
            <div class="sh-cdk-menu-item">
                <button
                    class="sh-cdk-nav-link"
                    [routerLink]="['/']"
                    [class.sh-active]="activeRoute === ''"
                    cdkMenuItem>
                    Startseite
                </button>
            </div>
            <div class="sh-cdk-menu-item">
                <button
                    class="sh-cdk-nav-link"
                    cdkMenuItem
                    [cdkMenuTriggerFor]="tools"
                    [class.sh-active]="activeRoute === 'tools'">
                    Tools
                </button>
            </div>
            <div class="sh-cdk-menu-item" disabled="">
                <button
                    class="sh-cdk-nav-link"
                    cdkMenuItem
                    routerLink="database"
                    [class.sh-active]="activeRoute === 'database'">
                    Datenbank
                </button>
            </div>
            <div class="sh-cdk-menu-item">
                <button
                    class="sh-cdk-nav-link"
                    cdkMenuItem
                    [cdkMenuTriggerFor]="guides"
                    disabled>
                    Guides
                </button>
            </div>
            <div class="sh-cdk-menu-item">
                <button
                    class="sh-cdk-nav-link"
                    cdkMenuItem
                    [cdkMenuTriggerFor]="community"
                    disabled>
                    Community
                </button>
            </div>
            <div class="sh-cdk-menu-item">
                <button
                    class="sh-cdk-nav-link"
                    cdkMenuItem
                    routerLink="price-tool"
                    [disabled]="!accountService.isUserLoggedIn()"
                    [class.sh-active]="activeRoute === 'price-tool'">
                    Preis Tool
                </button>
            </div>
            <div class="sh-cdk-menu-item">
                <button
                    class="sh-cdk-nav-link"
                    cdkMenuItem
                    [disabled]="!accountService.isUserLoggedIn()"
                    (click)="openFeedbackDialog()">
                    Feedback
                </button>
            </div>
        </div>
    </div>

    <ng-template #tools>
        <div class="sh-navbar" cdkMenuBar>
            <div class="sh-cdk-sub-menu" cdkMenu>
                <div
                    type="button"
                    class="sh-cdk-menu-item sh-menu-bar-item"
                    cdkMenuItem>
                    <div
                        class="sh-btn-nav sh-cdk-nav-link"
                        [routerLink]="['trading/routes']">
                        <span style="transform: skew(15deg)">Routenplaner</span>
                    </div>
                </div>
                <div class="sh-cdk-menu-item sh-menu-bar-item" cdkMenuItem>
                    <div
                        type
                        button
                        class="sh-btn-nav sh-cdk-nav-link"
                        [routerLink]="['trading/commodities']">
                        Warenübersicht
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #database>
        <div class="sh-cdk-sub-menu" #databaseMenu cdkMenu>
            <div class="sh-cdk-menu-item sh-menu-bar-item">
                <div
                    mat-icon-button
                    type="button"
                    class="sh-btn-nav sh-cdk-nav-link"
                    cdkMenuItem
                    [cdkMenuPosition]="cdkMenuPosition"
                    [cdkMenuTriggerFor]="vehicles">
                    <img
                        src="../../../../assets/icons/navigation/ships.png"
                        height="10"
                        width="auto" />
                    <span>Schiffe</span>
                </div>
            </div>
            <div class="sh-cdk-menu-item sh-menu-bar-item">
                <div
                    mat-icon-button
                    type="button"
                    class="sh-btn-nav sh-cdk-nav-link"
                    cdkMenuItem>
                    <img src="../../../../assets/icons/navigation/items.png" />
                    <span>Items</span>
                </div>
            </div>
            <div class="sh-cdk-menu-item sh-menu-bar-item">
                <button class="sh-btn-nav sh-cdk-nav-link" cdkMenuItem>
                    <img
                        src="../../../../assets/icons/navigation/location.png" />
                    <span>Locations</span>
                </button>
            </div>
            <div class="sh-cdk-menu-item sh-menu-bar-item">
                <button class="sh-btn-nav sh-cdk-nav-link" cdkMenuItem>
                    <img
                        src="../../../../assets/icons/navigation/planets.png" />
                    <span>Planeten</span>
                </button>
            </div>
        </div>
    </ng-template>

    <ng-template #guides> </ng-template>
    <ng-template #community> </ng-template>

    <ng-template #vehicles>
        <div class="sh-cdk-menu" cdkMenu>
            <div class="sh-menu-bar-item" cdkMenuItem>
                <div
                    class="manufacturer-grid sh-color-bg-primary"
                    style="z-index: 1001">
                    <div
                        class="manufacturer-grid-item"
                        [routerLink]="['/database/ships', 'all']">
                        <label>Alle Schiffe</label>
                    </div>
                    <div
                        class="manufacturer-grid-item"
                        *ngFor="let man of manufacturers"
                        [routerLink]="[
                            '/database/ships',
                            man.code.toLowerCase()
                        ]">
                        <label>{{
                            man.code === 'MISC' ? man.code : man.name
                        }}</label>
                        <div>
                            <img
                                class="sh-manufacturer-select-logo"
                                src="assets/logos/manufacturers/{{
                                    man.code
                                }}_WHITE.png" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</section>
