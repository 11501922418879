import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard{
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {

    // Get the roles required from the route.
    const requiredRoles = route.data.roles;
    const loginRequired = route.data.loginRequired;

    if (loginRequired && !this.keycloak.getKeycloakInstance().authenticated) {
      await this.router.navigate(['/']);

      return false;
    }

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    const accessAllowed = requiredRoles.some((role) => {
      return this.roles.includes(role);
    });
    if (accessAllowed) {
      return true;
    }

    await this.router.navigate(['/']);

    return false;
  }
}
