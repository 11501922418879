<div class="modal-body sh-modal sh-bg-primary" tabindex="-1">
  <h1>Formular</h1>
  <form novalidate [formGroup]="mainForm" (ngSubmit)="saveData(mainForm.value)">
    <div *ngFor="let fCtrl of questForm?.formControls">
      <div class="row g-3 align-items-center" [ngSwitch]="fCtrl?.type">
        <div class="col-5 text-wrap">
          <label>{{fCtrl?.lable}}
            <span *ngIf="fCtrl?.required"> *</span>
          </label>
        </div>
        <div class="col-2"></div>
        <div class="col-5" *ngSwitchCase="'TEXT'">
          <input type="text" class="form-control sh-input" [formControlName]="fCtrl?.id"
                 [placeholder]="fCtrl?.placeholder">
        </div>
        <div class="col-5" *ngSwitchCase="'LONGTEXT'">
          <textarea class="form-control sh-input" [formControlName]="fCtrl?.id"></textarea>
        </div>
        <div class="col-5" *ngSwitchCase="'CHOICE'">
          <select class="form-control sh-input" [formControlName]="fCtrl?.id">
            <option>--- Bitte wählen ---</option>
            <option *ngFor="let choice of fCtrl?.choices" [value]="choice?.value">{{choice?.name}}</option>
          </select>
        </div>
        <div class="col-5" *ngSwitchCase="'NUMBER'">
          <input type="number" class="form-control sh-input" [formControlName]="fCtrl?.id">
        </div>
      </div>

      <hr class="sh-gradient-accent">
    </div>
    <button type="submit" class="sh-btn sh-btn-outline-success" [disabled]="mainForm.invalid">Speichern</button>
  </form>
</div>
