<div class="footer grid-container bg-dark">
  <div class="grid-item grid-container bg-dark">
    <div id="disclaimer" class="grid-item">
      <p class="card-text text-muted text-justify">
        <small>This site is not endorsed
          by or affiliated with the Cloud Imperium or Roberts Space Industries group of
          companies. All game content and materials are copyright Cloud Imperium Rights LLC
          and Cloud Imperium Rights Ltd.<br> <strong> Star Citizen®, Squadron 42®, Roberts Space Industries®,
            and Cloud Imperium® are registered trademarks of Cloud Imperium Rights LLC. All
            rights reserved.</strong></small></p>
    </div>
    <div class="grid-item">
      <img id="sh-community-logo" src="../../../assets/logos/MadeByTheCommunity_White.png"
           alt="Made by the community logo" class="sh-community-logo">
    </div>
  </div>
  <div class="grid-item bg-dark">
    <h5 class="card-title text-light">Links</h5>
    <ul class="footer-list">
      <li><span i18n="@@headingSupport">Unterstütze die Seite</span>
        <ul>
          <li>
            <span i18n="@@spanOn">auf</span> <a href="https://www.patreon.com/KnebelDE" target="blank"> Patreon</a>
          </li>
        </ul>
      </li>
      <li>
        <span i18n="@@lblVisit">Besuche</span> <a href="https://robertsspaceindustries.com" target="_blank"> Star
        Citizen</a>
      </li>
    </ul>
  </div>
  <div class="grid-item bg-dark">
    <h5 class="card-title text-light" i18n="@@lblContact">Kontakt</h5>
    <ul class="footer-list">
      <li>
        <span i18n="@@spanWith">Per </span> <a href="mailto:contact@star-head.de"> Email</a>
      </li>
    </ul>
  </div>
  <div class="grid-item bg-dark">
    <h5 class="card-title text-light" i18n="@@headingLegal">Legal</h5>
    <ul class="footer-list">
      <li>
        <a role="button" class="btn-link" routerLink="/privacy-policy" i18n="@@privacy_policy">Privacy policy</a>
      </li>
      <li>
        <a role="button" class="btn-link" routerLink="/impressum" >Impressum</a>
      </li>
    </ul>
  </div>
</div>
