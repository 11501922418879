<sh-content-container
    class="sh-options-container"
    heading="Waren"
    style="max-height: calc(100vh - 280px)">
    <div class="commodity-list-wrapper">
        <form
            style="
                position: sticky;
                top: 0;
                background: #0a2232;
                background-blend-mode: color;
                opacity: 1;
                z-index: 999;
            ">
            <mat-form-field appearance="standard">
                <mat-label>Ware suchen</mat-label>
                <input matInput [formControl]="searchInput" />
                <button matSuffix mat-icon-button (click)="resetAutoInput()">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-form-field>
        </form>
    
        <mat-selection-list [multiple]="false" [(ngModel)]="selectedOptions">
            <mat-list-option
                *ngFor="let commodity of filteredCommodities"
                [value]="commodity?.id"
                (click)="selectCommodity(commodity)">
                <mat-icon mat-list-icon>inventory_2</mat-icon>
                <div class="sh-commodity-list-item" mat-line>
                    {{ commodity?.name }}
                </div>
                <div class="sh-top-corners"></div>
                <div class="sh-bottom-corners"></div>
            </mat-list-option>
        </mat-selection-list>
    </div>
</sh-content-container>
