<ul class="dropdown-menu">
  <li class="dropdown-submenu" *ngFor="let g of group">
    <a tabindex="-1">{{g.name}}</a>
    <ul class="dropdown-menu">
      <li *ngFor="let item of g.items">
        <a tabindex="-1" (click)="selectedItem.emit(item)">{{item[PropertyName]}}</a>
      </li>
    </ul>
  </li>
</ul>
