import { Injectable } from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import { Observable, of, ReplaySubject } from "rxjs";
import { KeycloakInstance, KeycloakLoginOptions, KeycloakProfile } from "keycloak-js";
import { HttpClient, HttpParams } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from "../../environments/environment";
import { LoggerService } from "@starhead/service/logger.service";

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private redirectUrl = 'https://login.star-head.de/auth/realms/starhead/account?redirect_uri=' + window.location.origin;
  private keycloak: KeycloakInstance;
  private userProfileSource = new ReplaySubject<KeycloakProfile>(1);
  userProfile$ = this.userProfileSource.asObservable();
  private token = '';
  private decodedToken: any;
  private refreshToken: any;
  private jwtHelper = new JwtHelperService();
  private loggedInSource = new ReplaySubject<boolean>(1);
  loggedIn$ = this.loggedInSource.asObservable();

  constructor(private keycloakService: KeycloakService, private http: HttpClient, private logger: LoggerService) {
    this.keycloak = keycloakService.getKeycloakInstance();
    this.keycloakService.isLoggedIn().then(x => this.loggedInSource.next(x));
    this.refreshToken = this.keycloak.refreshTokenParsed;
    this.keycloak.onTokenExpired = () => {
      this.tryRefreshAccesstoken();
    }
  }

  loginIn(): void {
    const stage = environment.stage;
    const options: KeycloakLoginOptions = {
      scope: `email ${stage}`,
      locale: 'de',
    };
    this.keycloak.login(options);
  }

  tryRefreshAccesstoken() {
    this.decodedToken = this.keycloak.tokenParsed;

    if(!this.keycloak.authenticated) return;

    this.token = this.keycloak.token;
    this.keycloak.updateToken(30).then(success => {
      if(success) {
        this.token = this.keycloak.token;
        this.decodedToken = this.keycloak.tokenParsed;
      }
    }, rejected => {
      this.logger.warningWithId(rejected, AccountService.name, this.tryRefreshAccesstoken.name);
      this.keycloakService.logout();
    }).catch(reason => {
      this.logger.errorWithId(reason, AccountService.name, this.tryRefreshAccesstoken.name);
      this.loginIn();
    });
  }

  register(): void {
    this.keycloak.register({scope: `email ${environment.stage}`, locale: 'de'});
  }

  private loginWithCredentials(username: string, password: string): void {
    const loginUrl = 'https://login.star-head.de/auth/realms/starhead/protocol/openid-connect/token';

    const body = new HttpParams()
      .set("client_id", "starhead-test")
      .set("grant_type", "password")
      .set("scope", "openid email starhead-test")
      .set("username", username)
      .set("password", password);
    const options = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    this.http.post(loginUrl, body.toString(), options).subscribe((response) => {

      console.log('response from login: ' + response);
      this.keycloak.token = response['access_token'];
      this.keycloak.idToken = response['id_token'];
      this.keycloak.sessionId = response['session_state'];
    }, error => console.log('error in login: ', error));
  }

  logout(): void {
    this.keycloakService.logout();
  }

  async loadUserProfile(): Promise<void> {
    if (this.keycloak.authenticated) {
      const profile = await this.keycloak.loadUserProfile();
      // const p = await this.keycloakService.loadUserProfile();
      this.userProfileSource.next(profile);
    }
  }

  editUserProfile(): void {
    window.open(this.redirectUrl, '_blank');
  }

  getIsUserAdmin(): Observable<boolean> {
    if(this.keycloak.authenticated) {
      const roles = this.keycloakService.getUserRoles(true);
      const adminRole = roles.includes('admin');
      return of(adminRole);
    }
    return of(false);
  }

  getIsUserAdminOrModerator(): Observable<boolean> {
    if(this.keycloak.authenticated) {
      const roles = this.keycloakService.getUserRoles(true);
      const adminRoles = roles.includes( 'admin');
      const modRoles = roles.includes('moderator');
      return of(adminRoles || modRoles);
    }
    return of(false);
  }

  getToken(): string {
    return this.token || '';
  }

  isUserLoggedIn(): boolean {
    return this.keycloak.authenticated;
  }
}
