<div class="dropdown sh-top-navbar">
  <div class="sh-btn sh-nav-list-item dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown"
     aria-expanded="false">
    Dropdown button
  </div>
  <ul class="dropdown-menu dropdown-menu-dark sh-dropdown-menu" aria-labelledby="dropdownMenuButton2">
    <li class="manufacturer-grid">
      <div class="manufacturer-grid-item">
        <label>Aegis Dynamics</label>
        <img class="sh-manufacturer-select-logo" src="../../../../assets/logos/manufacturers/AEGS_WHITE.png">
      </div>
      <div class="manufacturer-grid-item">
        <label>Anvil Aerospace</label>
        <img class="sh-manufacturer-select-logo" src="assets/logos/manufacturers/ANVL_WHITE.png">
      </div>
      <div class="manufacturer-grid-item">
        <label>Argo Astronautics</label>
        <img class="sh-manufacturer-select-logo" src="assets/logos/manufacturers/ARGO_WHITE.png">
      </div>
      <div class="manufacturer-grid-item">
        <label>AOPOA</label>
        <img class="sh-manufacturer-select-logo" src="assets/logos/manufacturers/AOPOA_WHITE.png">
      </div>
      <div class="manufacturer-grid-item">
        <label>Crusader Ind.</label>
        <img class="sh-manufacturer-select-logo" src="../../../../assets/logos/manufacturers/CRUS_WHITE.png">
      </div>
      <div class="manufacturer-grid-item">
        <label>Consolidated Outland</label>
        <img class="sh-manufacturer-select-logo" src="assets/logos/manufacturers/CNOU_WHITE.png">
      </div>
    </li>
  </ul>
</div>
