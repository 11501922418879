import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular-ivy';
import { BrowserTracing } from "@sentry/tracing";
import Plausible from "plausible-tracker";

const NGREF = 'ngRef';

Sentry.init({
  dsn: "https://b7dcc72dd7684638b7a1013376d497b3@sentry.star-head.de/3",
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: ["localhost", "https://www.star-head.de", "https://release.star-head.de"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  tracesSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
  // document.head.insertAdjacentHTML("beforeend", '<script defer data-domain="star-head.de"' +
  //   ' src="/js/script.js"></script>');

  const plausible = Plausible({
    domain: 'star-head.de',
    apiHost: 'https://analytics.star-head.de'
  });
  plausible.enableAutoPageviews();
} else if(!environment.production && environment.stage === 'starhead-prod') {
  // document.head.insertAdjacentHTML("beforeend", '<script defer data-domain="release.star-head.de"' +
  //   ' src="/js/script.js"></script>');
  const plausible = Plausible({
    domain: 'release.star-head.de',
    apiHost: 'https://analytics.star-head.de'
  });
  plausible.enableAutoPageviews();
}

const storedLang = localStorage.getItem('appLang');
const langFile = storedLang
  ? `/assets/i18n/${storedLang}.json`
  : '/assets/i18n/de.json';

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

