import { Component, Inject, OnInit } from "@angular/core";
import { ShopItemPriceDto } from "@starhead/core/dtos/shop-item-price-dto";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { LoggerService } from "@starhead/service/logger.service";
import { ShopService } from "@starhead/service/tradingServices/shop.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CommodityService } from "@starhead/service/commodity.service";
import { Commodity } from "@starhead/entities/trading/commodity";
import { CommodityTradeType } from "@starhead/core/interfaces/enums/commodity-trade-type";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: 'sh-admin-create-shop-item',
  templateUrl: './admin-create-shop-item.component.html',
  styleUrls: ['./admin-create-shop-item.component.scss']
})
export class AdminCreateShopItemComponent implements OnInit {
  public shopItem: ShopItemPriceDto;
  shopItemForm: FormGroup;
  commodities: Commodity[];
  filteredCommodities: Observable<Commodity[]>;
  tradeTypes = Object.values(CommodityTradeType);

  /// FormControls
  commodityControl = new FormControl('');

  constructor(private logger: LoggerService, private fb: FormBuilder, private commodityService: CommodityService, private shopService: ShopService, public dialogRef: MatDialogRef<AdminCreateShopItemComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.initForm();
  }

  ngOnInit(): void {
    this.commodityService.getCommodityList().subscribe(result => {
      this.commodities = result;
      this.filteredCommodities = this.commodityControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterCommodities(value ||'')),
      );
    });

  }

  initForm() {
    this.shopItemForm = this.fb.group({
      commodity: this.commodityControl,
      tradeType: [{value: ''}],
      shopName: [{value: this.data.shop.name, disabled: true}],
      maxInventoryCscu: [{value: ''}],
      pricePerItem: [{value: ''}],
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  private _filterCommodities(value: string): Commodity[] {
    const filterValue = value.toLowerCase();
    return this.commodities.filter(commodity => commodity.name.toLowerCase().includes(filterValue));
  }

  onSaveShopItem() {
    this.shopItem = this.shopItemForm.getRawValue();
  }

  commodityDisplayName(commodity: Commodity): string {
    return commodity && commodity.name ? commodity.name : '';
  }

}
