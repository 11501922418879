import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sh-tools-nav-bar',
  templateUrl: './tools-nav-bar.component.html',
  styleUrls: ['./tools-nav-bar.component.scss']
})
export class ToolsNavBarComponent implements OnInit {

  constructor() { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    // do nothing
  }

}
