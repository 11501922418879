import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'scCargo'
})
export class ScCargoPipe implements PipeTransform {

  transform(value: number, arg0: unknown): unknown {
    const lang = navigator.language;
    let result: string;
    switch (arg0) {
      case 'cSCU':
      case 'cSCU'.toLowerCase():
        const res = new Intl.NumberFormat(lang).format(value);
        result = `${res} cSCU`;
        break;
      case 'mSCU'.toLowerCase():
        const formatted = new Intl.NumberFormat(lang).format(value);
        result = `${formatted} µSCU`;
        break;
      case 'scu':
        value = (value);
        let scuResult = (value / 100).toFixed();
        scuResult = +scuResult > 1 ? scuResult : '1';
        result = `${+scuResult} SCU`;
        break;
      default:
        let defaultScu = value.toFixed();
        defaultScu = +defaultScu > 1 ? defaultScu : '1';
        result = `${new Intl.NumberFormat(lang).format(+defaultScu)} Einheiten`;
        break;
    }
    return result;
  }

}
