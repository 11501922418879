import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Shop } from '@starhead/entities/shops/shop';
import { Observable, of } from 'rxjs';
import { ShopUpdateDto } from '@starhead/core/dtos/shop-update-dto';
import { map } from 'rxjs/operators';
import { ShopItemPriceEntry } from '@starhead/entities/shops/shop-item-price-entry';
import { ShopType } from '@starhead/core/interfaces/trading/shop-type';
import { ShopItemData } from '@starhead/entities/shops/shop-item-data';
import { ShopItemEntry } from '@starhead/entities/shops/shop-item-entry';
import { CommodityTradeType } from '@starhead/core/interfaces/enums/commodity-trade-type';
import { ShopItemPriceDto } from '@starhead/core/dtos/shop-item-price-dto';
import { ShopItemTransferDto } from '@starhead/core/dtos/shop-item-transfer-dto';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class ShopService {
    private shopUrl = environment.apiUrl + '/shop';
    private shopItemUrl = environment.apiUrl + '/shopItem';

    shopCache = new Map();

    constructor(private http: HttpClient, private toastr: ToastrService) {}

    /**
     * SHOPS
     * */
    getShops(): Observable<Shop[]> {
        const cache = this.shopCache.get(Object.values('shops').join('-'));
        if (cache) {
            return of(cache);
        }
        return this.http.get<Shop[]>(this.shopUrl).pipe(
            map((response) => {
                this.shopCache.set(Object.values('shops').join('-'), response);
                return response;
            }),
        );
    }

    getShopsByCelestialObject(celestialObjectId: number): Observable<Shop[]> {
        const cache = this.shopCache.get(
            Object.values('shops').join('-') + celestialObjectId,
        );
        if (cache) {
            return of(cache);
        }
        return this.http.get<Shop[]>(this.shopUrl + `?celestialObjectFilter=${celestialObjectId}`).pipe(
            map((response) => {
                this.shopCache.set(
                    Object.values('shops').join('-') + celestialObjectId,
                    response,
                );
                return response;
            }),
        );
    }

    getShop(shopId: number): Observable<Shop> {
        const shop = [...this.shopCache.values()]
            .reduce((arr, elem) => arr.concat(elem.result), [])
            .find((cacheShop: Shop) => cacheShop?.id === shopId);

        if (shop) {
            return of(shop);
        }

        return this.http.get<Shop[]>(this.shopUrl).pipe(
            map((response) => {
                return response.find((item) => item.id === shopId);
            }),
        );
    }

    updateShop(shop: ShopUpdateDto) {
        return this.http
            .put<Shop>(this.shopUrl, shop, { observe: 'response' })
            .pipe(
                map((response) => {
                    if (response.status === 404) {
                        this.toastr.error(response.statusText, 'Status 404');
                    }
                    console.log(response.body);
                    return response.body;
                }),
            );
    }

    getShopItemPriceEntries(shopId: number): Observable<ShopItemPriceEntry[]> {
        return this.http.get<ShopItemPriceEntry[]>(
            `${this.shopUrl}/${shopId}/items`,
        );
    }

    getShopTypes(): Observable<ShopType[]> {
        return this.http.get<ShopType[]>(this.shopUrl + '/type');
    }

    createShopType(shopTypeName: string): Observable<ShopType> {
        return this.http.post<ShopType>(this.shopUrl + '/type', shopTypeName);
    }

    deleteShop(shopId: number) {
        return this.http.delete(this.shopUrl + `/${shopId}/delete`);
    }

    /**
     * SHOPITEMS
     * */
    getShopItemPrices(
        commodityId?: number,
        tradeType?: CommodityTradeType,
        locationId?: number,
    ): Observable<ShopItemPriceEntry[]> {
        return this.http.get<ShopItemPriceEntry[]>(this.shopItemUrl + '/price');
    }

    createShopItem(shopItem: ShopItemData): Observable<ShopItemEntry> {
        return this.http.put<ShopItemEntry>(this.shopItemUrl, shopItem);
    }

    addOrUpdateShopItem(
        shopItem: ShopItemTransferDto,
    ): Observable<ShopItemEntry> {
        const paramShop = {
            commodityId: shopItem.commodityId,
            shopId: shopItem.shop.id,
            pricePerItem: shopItem.pricePerItem,
            maxInventoryCscu: shopItem.maxInventoryCscu,
            tradeType: shopItem.tradeType,
            refreshCscuPerMinute: shopItem.refreshCscuPerMinute,
        };
        return this.http.put<ShopItemEntry>(this.shopItemUrl + '/price', [
            paramShop,
        ]);
    }

    addOrUpdateShopItemPrice(
        shopItemPrices: ShopItemPriceDto[],
    ): Observable<ShopItemPriceEntry[]> {
        return this.http.put<ShopItemPriceEntry[]>(
            this.shopItemUrl + '/price',
            shopItemPrices,
        );
    }

    deleteShopItem(shopItem: ShopItemEntry) {
        return this.http.delete(this.shopItemUrl + '/delete', {
            body: {
                tradeType: shopItem.tradeType,
                commodityId: shopItem.commodityId,
                shopId: shopItem.shopId,
            },
        });
    }
}
