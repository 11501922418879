import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, Renderer2} from '@angular/core';
import {AnimationBuilder, AnimationFactory} from '@angular/animations';
import {Subscription} from 'rxjs';
import {ShDropdownState} from '@starhead/core/directives/sh-dropdown.state';
import {shDropdownAnimation} from '@starhead/core/data/sh-dropdown-animation';

@Component({
  selector: 'sh-dropdown-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    style: 'display:block;position: absolute;z-index: 400'
  },
  template: `
    <div class="sh-dropdown"
         [class.show]="isOpen"
         [class.isOpen]="isOpen"><ng-content></ng-content>
    </div>
  `
})
export class ShDropdownContainerComponent implements OnDestroy {
  isOpen = false;

  private factoryDropdownAnimation: AnimationFactory;

  private subscription: Subscription;

  constructor(
    private state: ShDropdownState,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    builder: AnimationBuilder
  ) {
    this.factoryDropdownAnimation = builder.build(shDropdownAnimation);

    this.subscription = state.isOpenChange.subscribe((value: boolean) => {
      this.isOpen = value;
      const dropdown = this.elementRef.nativeElement.querySelector('.sh-dropdown-menu');

      this.renderer.addClass(this.elementRef.nativeElement.querySelector('div'), 'open');

      if (dropdown) {
        this.renderer.addClass(dropdown, 'show');

        this.renderer.setStyle(dropdown, 'left', '0');
        this.renderer.setStyle(dropdown, 'right', '0');
      }

      if (dropdown && this.state.isAnimated) {
        this.factoryDropdownAnimation.create(dropdown).play();
      }

      this.cdRef.markForCheck();
      this.cdRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
