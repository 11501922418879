
<!-- Table of Contents sidebar to scroll to the entries -->
<div class="sh-sidebar sh-bg-primary" tabindex="-1">
  <div class="sh-text-light">
    <h5>Inhalt</h5>
  </div>
  <a class="sh-btn-outline-accent mb-1" (click)="scrollTo('paragraph2')">Ihre Betroffenenrechte</a>
  <a class="sh-btn-outline-accent mb-1" (click)="scrollTo('paragraph3')">Erfassung allgemeiner Informationen beim
    Besuch unserer Website</a>
  <a class="sh-btn-outline-accent mb-1" (click)="scrollTo('paragraph4')">Cookies</a>
  <a class="sh-btn-outline-accent mb-1" (click)="scrollTo('paragraph5')">Widerspruch</a>
  <a class="sh-btn-outline-accent mb-1" (click)="scrollTo('paragraph6')">Kommentarfunktion</a>
  <a class="sh-btn-outline-accent mb-1" (click)="scrollTo('paragraph7')">Newsletter</a>
  <a class="sh-btn-outline-accent mb-1" (click)="scrollTo('paragraph8')">Kontaktformular</a>
  <a class="sh-btn-outline-accent mb-1" (click)="scrollTo('paragraph9')">Verwendung von Scriptbibliotheken</a>
  <a class="sh-btn-outline-accent mb-1" (click)="scrollTo('paragraph10')">SSL-Verschlüsselung</a>
  <a class="sh-btn-outline-accent mb-1" (click)="scrollTo('paragraph11')">Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</a>
  <a class="sh-btn-outline-accent mb-1" (click)="scrollTo('paragraph12')">Änderung unserer Datenschutzbestimmungen</a>
  <a class="sh-btn-outline-accent" (click)="scrollTo('paragraph13')">Fragen an den Datenschutzbeauftragten</a>
</div>
<!-- Privacy-Policy -->
<div class="policy-container">
  <h1 id="pagestart">Datenschutzerklärung</h1>
  <p>Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
  <p>KnebelDE</p>
  <h2 id="paragraph2">Ihre Betroffenenrechte</h2>
  <p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte
    ausüben:</p>
  <ul>
    <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
    <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
    <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
    <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen
      dürfen (Art. 18 DSGVO),
    </li>
    <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
    <li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns
      abgeschlossen haben (Art. 20 DSGVO).
    </li>
  </ul>
  <p>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft
    widerrufen.</p>
  <p>Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige
    Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige
    Behörde.</p>
  <p>Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter: <a
    href="https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html" target="_blank"
    rel="noopener nofollow">https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html</a>.</p>
  <p></p>
  <h2 id="paragraph3">Erfassung allgemeiner Informationen beim Besuch unserer Website</h2>
  <h3>Art und Zweck der Verarbeitung:</h3>
  <p>Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht registrieren oder anderweitig Informationen
    übermitteln, werden automatisch Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles)
    beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres
    Internet-Service-Providers, Ihre IP-Adresse und ähnliches. </p>
  <p>Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
  <ul>
    <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
    <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
    <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
    <li>zur Optimierung unserer Website.</li>
  </ul>
  <p>Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Informationen dieser Art werden von uns
    ggfs. anonymisiert statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu
    optimieren. </p>
  <h3>Rechtsgrundlage und berechtigtes Interesse:</h3>
  <p>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der
    Verbesserung der Stabilität und Funktionalität unserer Website.</p>
  <h3>Empfänger:</h3>
  <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer Webseite als
    Auftragsverarbeiter tätig werden.</p>
  <p></p>
  <h3>Speicherdauer:</h3>
  <p>Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist für die
    Daten, die der Bereitstellung der Website dienen, grundsätzlich der Fall, wenn die jeweilige Sitzung beendet
    ist. </p>
  <p> Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens 14 Tagen der Fall. Eine
    darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer anonymisiert, sodass
    eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.</p>
  <p></p>
  <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
  <p>Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben.
    Ohne die IP-Adresse ist jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht gewährleistet. Zudem
    können einzelne Dienste und Services nicht verfügbar oder eingeschränkt sein. Aus diesem Grund ist ein Widerspruch
    ausgeschlossen. </p>
  <p></p>
  <h2 id="paragraph4">Cookies</h2>
  <p>Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“. Bei Cookies handelt es sich um kleine
    Textdateien, die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unsere Webseite
    besuchen. </p>
  <p>Sie können Sie einzelne Cookies oder den gesamten Cookie-Bestand löschen. Darüber hinaus erhalten Sie Informationen
    und Anleitungen, wie diese Cookies gelöscht oder deren Speicherung vorab blockiert werden können. Je nach Anbieter
    Ihres Browsers finden Sie die notwendigen Informationen unter den nachfolgenden Links:</p>
  <ul>
    <li>Mozilla Firefox: <a href="https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen"
                            target="_blank" rel="nofollow noopener">https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen</a>
    </li>
    <li>Internet Explorer: <a
      href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies"
      target="_blank" rel="nofollow noopener">https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies</a>
    </li>
    <li>Google Chrome: <a href="https://support.google.com/accounts/answer/61416?hl=de" target="_blank"
                          rel="nofollow noopener">https://support.google.com/accounts/answer/61416?hl=de</a></li>
    <li>Opera: <a href="http://www.opera.com/de/help" target="_blank" rel="nofollow noopener">http://www.opera.com/de/help</a>
    </li>
    <li>Safari: <a href="https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE" target="_blank"
                   rel="nofollow noopener">https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE</a></li>
  </ul>
  <h3>Speicherdauer und eingesetzte Cookies:</h3>
  <p>Soweit Sie uns durch Ihre Browsereinstellungen oder Zustimmung die Verwendung von Cookies erlauben, können folgende
    Cookies auf unseren Webseiten zum Einsatz kommen:</p>
  <p></p>
  <h2>Technisch notwendige Cookies </h2>
  <h3>Art und Zweck der Verarbeitung: </h3>
  <p>Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu gestalten. Einige Elemente unserer Internetseite
    erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel identifiziert werden kann.</p>
  <p>Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung von Websites für die Nutzer zu
    vereinfachen. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies nicht angeboten werden.
    Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird.</p>
  <p>Für folgende Anwendungen benötigen wir Cookies:</p>
  <p>Übernahme von Spracheinstellungen</p>
  <h3>Rechtsgrundlage und berechtigtes Interesse: </h3>
  <p>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an einer
    nutzerfreundlichen Gestaltung unserer Website.</p>
  <h3>Empfänger: </h3>
  <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer Website als
    Auftragsverarbeiter tätig werden.</p>
  <p></p>
  <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
  <p>Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben.
    Ohne diese Daten ist jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht gewährleistet. Zudem können
    einzelne Dienste und Services nicht verfügbar oder eingeschränkt sein.</p>
  <h3 id="paragraph5">Widerspruch</h3>
  <p>Lesen Sie dazu die Informationen über Ihr Widerspruchsrecht nach Art. 21 DSGVO weiter unten.</p>
  <p></p>
  <h2>Registrierung auf unserer Website</h2>
  <h3>Art und Zweck der Verarbeitung:</h3>
  <p>Für die Registrierung auf unserer Website benötigen wir einige personenbezogene Daten, die über eine Eingabemaske
    an uns übermittelt werden. </p>
  <p>Zum Zeitpunkt der Registrierung werden zusätzlich folgende Daten erhoben:</p>
  <p></p>
  <p>Ihre Registrierung ist für das Bereithalten bestimmter Inhalte und Leistungen auf unserer Website erforderlich.</p>
  <h3>Rechtsgrundlage:</h3>
  <p>Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf Grundlage einer Einwilligung des Nutzers
    (Art. 6 Abs. 1 lit. a DSGVO).</p>
  <h3>Empfänger:</h3>
  <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer Website als
    Auftragsverarbeiter tätig werden.</p>
  <p></p>
  <h3>Speicherdauer:</h3>
  <p>Daten werden in diesem Zusammenhang nur verarbeitet, solange die entsprechende Einwilligung vorliegt. </p>
  <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
  <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung. Ohne die
    Bereitstellung Ihrer personenbezogenen Daten können wir Ihnen keinen Zugang auf unsere angebotenen Inhalte
    gewähren. </p>
  <p></p>
  <h2 id="paragraph6">Kommentarfunktion</h2>
  <h3>Art und Zweck der Verarbeitung:</h3>
  <p>Wenn Nutzer Kommentare auf unserer Website hinterlassen, werden neben diesen Angaben auch der Zeitpunkt ihrer
    Erstellung und der zuvor durch den Websitebesucher gewählte Nutzername gespeichert. Dies dient unserer Sicherheit,
    da wir für widerrechtliche Inhalte auf unserer Webseite belangt werden können, auch wenn diese durch Benutzer
    erstellt wurden.</p>
  <h3>Rechtsgrundlage:</h3>
  <p>Die Verarbeitung der als Kommentar eingegebenen Daten erfolgt auf der Grundlage eines berechtigten Interesses (Art.
    6 Abs. 1 lit. f DSGVO).</p>
  <p>Durch Bereitstellung der Kommentarfunktion möchten wir Ihnen eine unkomplizierte Interaktion ermöglichen. Ihre
    gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert.</p>
  <h3>Empfänger:</h3>
  <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
  <p></p>
  <h3>Speicherdauer:</h3>
  <p>Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist
    grundsätzlich der Fall, wenn die Kommunikation mit dem Nutzer abgeschlossen ist und das Unternehmen den Umständen
    entnehmen kann, dass der betroffene Sachverhalt abschließend geklärt ist. Wir behalten uns die Löschung ohne Angaben
    von Gründen und ohne vorherige oder nachträgliche Information vor.</p>
  <p>Außerdem können Sie Ihren Kommentar jederzeit durch uns löschen lassen. Schreiben Sie dafür bitte eine E-Mail an
    den unten aufgeführten Datenschutzbeauftragten bzw. die für den Datenschutz zuständige Person und übermitteln den
    Link zu Ihrem Kommentar sowie zu Identifikationszwecken die bei der Erstellung des Kommentars verwendete
    E-Mail-Adresse.</p>
  <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
  <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Ohne die Bereitstellung Ihrer
    personenbezogenen Daten können wir Ihnen keinen Zugang zu unserer Kommentarfunktion gewähren.</p>
  <p></p>
  <h2 id="paragraph7">Newsletter</h2>
  <h3>Art und Zweck der Verarbeitung:</h3>
  <p>Für die Zustellung unseres Newsletters erheben wir personenbezogene Daten, die über eine Eingabemaske an uns
    übermittelt werden.</p>
  <p>Für eine wirksame Registrierung benötigen wir eine valide E-Mail-Adresse. Um zu überprüfen, dass eine Anmeldung
    tatsächlich durch den Inhaber einer E-Mail-Adresse erfolgt, setzen wir das „Double-Opt-in“-Verfahren ein. Hierzu
    protokollieren wir die Anmeldung zum Newsletter, den Versand einer Bestätigungsmail und den Eingang der hiermit
    angeforderten Antwort. Weitere Daten werden nicht erhoben. </p>
  <h3>Rechtsgrundlage:</h3>
  <p>Auf Grundlage Ihrer ausdrücklich erteilten Einwilligung (Art. 6 Abs. 1 lit. a DSGVO), übersenden wir Ihnen
    regelmäßig unseren Newsletter bzw. vergleichbare Informationen per E-Mail an Ihre angegebene E-Mail-Adresse. </p>
  <p>Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer Nutzung für den Newsletterversand können Sie
    jederzeit mit Wirkung für die Zukunft widerrufen. In jedem Newsletter findet sich dazu ein entsprechender Link.
    Außerdem können Sie sich jederzeit auch direkt auf dieser Website abmelden oder uns Ihren Widerruf über die am Ende
    dieser Datenschutzhinweise angegebene Kontaktmöglichkeit mitteilen.</p>
  <h3>Empfänger:</h3>
  <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
  <p></p>
  <h3>Speicherdauer:</h3>
  <p>Die Daten werden in diesem Zusammenhang nur verarbeitet, solange die entsprechende Einwilligung vorliegt. Danach
    werden sie gelöscht.</p>
  <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
  <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer Einwilligung. Ohne
    bestehende Einwilligung können wir Ihnen unseren Newsletter leider nicht zusenden.</p>
  <h3>Widerruf der Einwilligung:</h3>
  <p>Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer Nutzung für den Newsletterversand können Sie
    jederzeit mit Wirkung für die Zukunft widerrufen. Die Abmeldung kann über den in jeder E-Mail enthaltenen Link oder
    beim unten aufgeführten Datenschutzbeauftragten bzw. der für den Datenschutz zuständigen Person beantragt
    werden. </p>
  <p></p>
  <h2 id="paragraph8">Kontaktformular</h2>
  <h3>Art und Zweck der Verarbeitung:</h3>
  <p>Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen Kommunikation mit Ihnen gespeichert. Hierfür
    ist die Angabe einer validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient der Zuordnung der Anfrage
    und der anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist optional.</p>
  <h3>Rechtsgrundlage:</h3>
  <p>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt auf der Grundlage eines berechtigten
    Interesses (Art. 6 Abs. 1 lit. f DSGVO).</p>
  <p>Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine unkomplizierte Kontaktaufnahme ermöglichen. Ihre
    gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert.</p>
  <p>Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt die Verarbeitung der in das
    Kontaktformular eingegebenen Daten zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 lit. b DSGVO).</p>
  <h3>Empfänger:</h3>
  <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
  <p></p>
  <h3>Speicherdauer:</h3>
  <p>Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage gelöscht.</p>
  <p>Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den gesetzlichen Aufbewahrungsfristen nach HGB und
    löschen Ihre Daten nach Ablauf dieser Fristen. </p>
  <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
  <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir können Ihre Anfrage jedoch nur bearbeiten,
    sofern Sie uns Ihren Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.</p>
  <p></p>
  <h2 id="paragraph9">Verwendung von Scriptbibliotheken (Google Webfonts)</h2>
  <p>Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf dieser
    Website „Google Web Fonts“ der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; nachfolgend
    „Google“) zur Darstellung von Schriften.</p>
  <p>Weitere Informationen zu Google Web Fonts finden Sie unter <a href="https://developers.google.com/fonts/faq"
                                                                   rel="noopener nofollow" target="_blank">https://developers.google.com/fonts/faq</a>
    und in der Datenschutzerklärung von Google: <a href="https://www.google.com/policies/privacy/"
                                                   rel="noopener nofollow" target="_blank">https://www.google.com/policies/privacy/</a>.
  </p>
  <p></p>
  <h2>Eingebettete YouTube-Videos</h2>
  <p>Auf unserer Website betten wir YouTube-Videos ein. Betreiber der entsprechenden Plugins ist die YouTube, LLC, 901
    Cherry Ave., San Bruno, CA 94066, USA (nachfolgend „YouTube“). Die YouTube, LLC ist einer Tochtergesellschaft der
    Google LLC, 1600 Amphitheatre Pkwy, Mountain View, CA 94043, USA (nachfolgend „Google“). Wenn Sie eine Seite mit dem
    YouTube-Plugin besuchen, wird eine Verbindung zu Servern von YouTube hergestellt. Dabei wird YouTube mitgeteilt,
    welche Seiten Sie besuchen. Wenn Sie in Ihrem YouTube-Account eingeloggt sind, kann YouTube Ihr Surfverhalten Ihnen
    persönlich zuzuordnen. Dies verhindern Sie, indem Sie sich vorher aus Ihrem YouTube-Account ausloggen. </p>
  <p>Wird ein YouTube-Video gestartet, setzt der Anbieter Cookies ein, die Hinweise über das Nutzerverhalten
    sammeln. </p>
  <p>Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch YouTube erhalten Sie in
    den Datenschutzerklärungen des Anbieters, Dort erhalten Sie auch weitere Informationen zu Ihren diesbezüglichen
    Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre (<a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>).
  </p>
  <h3>Widerruf der Einwilligung:</h3>
  <p>Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen Opt-out oder ein Blockieren der Datenübertragung
    angeboten. Wenn Sie eine Nachverfolgung Ihrer Aktivitäten auf unserer Website verhindern wollen, widerrufen Sie
    bitte im Cookie-Consent-Tool Ihre Einwilligung für die entsprechende Cookie-Kategorie oder alle technisch nicht
    notwendigen Cookies und Datenübertragungen. In diesem Fall können Sie unsere Website jedoch ggfs. nicht oder nur
    eingeschränkt nutzen.</p>
  <p></p>
  <h2 id="paragraph10">SSL-Verschlüsselung</h2>
  <p>Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik
    entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.</p>
  <p></p>
  <hr>
  <h2 id="paragraph11">Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h2>
  <h3>Einzelfallbezogenes Widerspruchsrecht</h3>
  <p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung
    Sie betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO (Datenverarbeitung auf der
    Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung
    gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.</p>
  <p>Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können
    zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten
    überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
  <h3>Empfänger eines Widerspruchs</h3>

  <p>KnebelDE
    <br>c/o Block Services
    <br>Stuttgarter Str. 106
    <br>70736 Fellbach</p>
  <hr>
  <h2 id="paragraph12">Änderung unserer Datenschutzbestimmungen</h2>
  <p>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen
    Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der
    Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.</p>
  <h2 id="paragraph13">Fragen an den Datenschutzbeauftragten</h2>
  <p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für
    den Datenschutz verantwortliche Person in unserer Organisation:</p>
  <p></p>
  <p><em>Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt, den Experten für <a
    href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/" target="_blank" rel="noopener">externe
    Datenschutzbeauftragte</a> (Version #2020-09-30).</em></p></div>
<!-- Scoll to top Button -->
<button class="sh-btn sh-btn-outline-accent sh-sticky" (click)="scrollTo('sh-top-bar')">
  Nach Oben
</button>
