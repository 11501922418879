import { Component, OnInit } from '@angular/core';
import {ServerStatusService} from '@starhead/service/server-status.service';
import {ServerStatus} from '@starhead/core/entities/server-status';

@Component({
  selector: 'sh-server-status',
  templateUrl: './server-status.component.html',
  styleUrls: ['./server-status.component.scss']
})
export class ServerStatusComponent implements OnInit {
  statusPU: ServerStatus;
  statusPTU: ServerStatus;
  puStatusHtml: string;
  ptuStatusHtml: string;
  isPUOnline = false;
  testGroupLbl: string;

  constructor(private serverStatusService: ServerStatusService) { }

  ngOnInit(): void {
    this.serverStatusService.getStatus('pu').subscribe(status => this.statusPU = status);
    this.serverStatusService.getStatus('ptu').subscribe(status => this.statusPTU = status);
    this.checkServerOnline();
    this.puStatusHtml = `<div><p class="online">Server: ${this.statusPU.server}</p><p>Version: ${this.statusPU.version}</p></div>`;
    this.ptuStatusHtml = `<div><p>Server: ${this.statusPTU.server}</p><p>Version: ${this.statusPTU.version}</p><p>${`TestGruppe`}: ${this.statusPTU.testgroup}</p></div>`;

  }

  checkServerOnline(): void {
    this.isPUOnline = this.statusPU.server === 'online' ? true : false;
  }

}
