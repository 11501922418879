<div class="d-flex justify-content-center">
    <div class="content-frame">
        <img
            src="assets/images/background/starhead_hero.webp"
            alt="road_to_release_header_image"
            class="hero-image" />
        <div class="inner-content-frame">
            <div class="tiles-wrapper">
                <a *ngFor="let article of articles" class="article-tile" [routerLink]="['./news', article.id]">
                    <img
                    [src]="article.imageUrl"
                    alt="article_image"
                    class="article-image" />
                    <div class="background"></div>
                    <div class="title">
                        <h1>{{article.title}}</h1>
                        <div class="sh-top-corners"></div>
                        <div class="sh-bottom-corners"></div>
                    </div>
                    <p>
                        {{article.summary}}
                    </p>
                </a>
            </div>

            <sh-content-container heading="Wingman AI & StarHead">
                <div class="content-wrapper">
                    <img
                        src="assets/images/wingman/WingmanAI-StarHead.png"
                        alt="wingman_ai_and_starhead"
                        class="content-image" />
                    <p>
                        Wingman AI ermöglicht es euch, mit eurer Stimme mit
                        verschiedenen KI-Wingmen zu sprechen und dann mit ihnen
                        zu interagieren. Die KI kann dann z.B. Kommandos
                        ausführen, wie z.B. das Öffnen der Türen oder das
                        Starten des Schiffes. <br />
                        Zusammen mit StarHead ist Wingman AI aber noch viel
                        mächtiger. Mit Hilfe der StarHead API kann Wingman AI
                        euch Informationen zu Handelsrouten geben. Fragt einfach
                        und die StarHead AI wird euch nach den Informationen
                        fragen, die sie benötigt, um euch die beste Handelsroute
                        zu berechnen. <br /><br />
                        Falls ihr mehr über Wingman AI erfahren wollt, schaut
                        doch mal auf der
                        <a target="blank" href="https://wingman-ai.com"
                            >Wingman AI Website</a
                        >
                        vorbei. Dort seht ihr auch, was Wingman AI noch alles
                        kann und ihr bekommt Unterstützung bei Fragen oder
                        Problemen. <br /><br />
                        Mit Wingman AI und StarHead seid ihr bestens für eure
                        Handelsreisen in Star Citizen gerüstet. Darüber hinaus
                        könnt ihr Wingman AI auch für andere Spiele nutzen, oder
                        sogar eure eigenen KI-Wingmen erstellen.
                        Es gibt viele Einstellungsmöglichkeiten, um Wingman AI
                        an eure Bedürfnisse anzupassen. Ihr könnt z.B. die
                        Sprache, die Stimme oder wie die KI mit euch interagiert
                        ändern. <br /><br />
                        Oder ihr nutzt den umfangreichen Command Editor, um
                        eigene Kommandos zu erstellen. Mit dem Command Editor
                        könnt ihr auch eigene Key Bindings erstellen, um z.B. euer
                        Schiff zu steuern oder andere Aktionen auszuführen.
                    </p>
                    <div class="screenshots-wrapper">
                        <a href="assets/images/wingman/chat.png" class="screenshot" target="_blank">
                            <img src="assets/images/wingman/chat.png" alt="">
                        </a>
                        <a href="assets/images/wingman/settings.png" class="screenshot" target="_blank">
                            <img src="assets/images/wingman/settings.png" alt="">
                        </a>
                        <a href="assets/images/wingman/commands.png" class="screenshot" target="_blank">
                            <img src="assets/images/wingman/commands.png" alt="">
                        </a>
                    </div>
                    <p>
                        Als spezielle Aktion für die StarHead Community, könnt
                        ihr Wingman AI für 2 Wochen kostenlos nutzen.
                        Ladet euch hier die Version herunter, meldet euch an und ihr könnt
                        direkt loslegen, ohne irgendwelche API Keys
                        selber kaufen zu müssen:<br />
                    </p>
                    <a
                        href="https://wingman-ai.com"
                        target="blank"
                        class="sh-btn sh-btn-outline-accent sh-account-btn download">
                        Download Wingman AI
                    </a>
                    <span style="margin-top: 20px;">
                        <h3>Was kann ich StarHead Wingman AI fragen?</h3>
                        <p>
                            An sich kannst du alles fragen, da im Hintergrund die KI (GPT-4) versucht auf alles mögliche zu antworten. <br>
                            Um dir aber den Einstieg zu erleichtern, haben wir hier ein paar Beispiele für dich:
                        </p>
                        <ul>
                            <li>"Kannst du mir bitte eine gute Handelsroute geben?"</li>
                            <li>"Fahre das Fahrwerk aus!"</li>
                            <li>"Was ist der Unterschied zwischen einer Constellation Taurus und einer Constellation Andromeda?"</li>
                            <li>"Erzähle mir einen Witz im Star Citizen Universum."</li>
                            <li>"Wie groß ist der Frachtraum der Caterpillar?"</li>
                            <li>"Wiederhole bitte die letzte Handelsroute!"</li>
                            <li>"Wie groß ist der Gewinn bei dieser Route?"</li>
                            <li>"Starte das Schiff und erbitte Starterlaubnis!"</li>
                            <li>"Antworte mir ab jetzt immer auf Englisch!"</li>
                            <li>...</li>
                        </ul>
                    </span>
                </div>
            </sh-content-container>
            <sh-content-container heading="Features">
                <ul>
                    <li>
                        <div>
                            <h5>Handelsplaner</h5>
                            <p>
                                Mit dem Handelsplaner könnt ihr euch Routen
                                berechnen lassen, die abhängig von eurem Schiff,
                                dem verbauten QuantumDrive und einem Gewählten
                                Startpunkt die beste Handelsroute anbieten.
                            </p>
                        </div>
                    </li>
                    <li>
                        <div>
                            <h5>Warenübersicht</h5>
                            <p>
                                Die Warenübersicht verschafft euch Informationen
                                dazu, wo Ihr Waren(Commodities) kaufen oder auch
                                verkaufen könnt. Es wird bei jeder Location der
                                jeweilige Einkaufs- bzw. Verkaufspreis
                                angezeigt.
                            </p>
                        </div>
                    </li>
                    <li>
                        <div>
                            <h5>StarHead Account</h5>
                            <p>
                                Ihr könnt euch einen Account erstellen, um die
                                erweiterten Funktionalitäten von StarHead zu
                                nutzen. Dazu zählen aktuell das Preis Tool, in
                                dem ihr Preise für waren eintragen könnt, oder
                                auch das Feedback Tool.
                            </p>
                        </div>
                    </li>
                    <li>
                        <div>
                            <h5>Preis Tool (Temporär)</h5>
                            <p>
                                Das Preis Tool ist nur temporär und erlaubt euch
                                das Eintragen von Preisen für
                                Waren(Commodities). Das Tool wird später durch
                                unser Questsystem abgelöst.
                            </p>
                        </div>
                    </li>
                    <li>
                        <div>
                            <h5>Feedback</h5>
                            <p>
                                Ihr habt die Möglichkeit als angemeldeter
                                Benutzer Feedback zu eurem Erlebnis auf StarHead
                                zu geben. Wählt dazu einen Bereich im Formular
                                aus und schreibt euer Feedback dazu. <br />
                                Sei es ein Fehler den ihr gefunden habt oder ein
                                Verbesserungsvorschlag für das aktuelle Design.
                                Jede Art von Feedback ist herzlich willkommen.
                            </p>
                        </div>
                    </li>
                </ul>
            </sh-content-container>
        </div>
    </div>
</div>
