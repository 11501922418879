<button
    class="delete-button"
    mat-mini-fab
    color="warn"
    (click)="removeEntry.emit(true)">
    <mat-icon>delete</mat-icon>
</button>
<sh-content-container heading="Neuer Preis">
    <form
        [formGroup]="priceEntryForm"
        (ngSubmit)="patchValues(priceEntryForm.value)"
        novalidate
        class="sh-price-item-tile">
        <mat-form-field appearance="standard">
            <mat-label>Himmelskörper</mat-label>
            <mat-select
                formControlName="celestialObject"
                [compareWith]="compareWithFn">
                <mat-form-field
                    appearance="standard"
                    (click)="$event.stopPropagation()"
                    color="accent">
                    <mat-label>Himmelskörper suchen</mat-label>
                    <input
                        matInput
                        (click)="$event.stopPropagation()"
                        formControlName="celestialObjectSearch" />
                </mat-form-field>
                <mat-option
                    *ngFor="let co of celestialObjectOptions | async"
                    [value]="co"
                    (click)="setSelectedCelestialObject(co)"
                    >{{ co.name }}</mat-option
                >
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="standard">
            <mat-label>Ort</mat-label>
            <mat-select
                formControlName="location"
                [compareWith]="compareWithFn">
                <mat-form-field
                    appearance="standard"
                    (click)="$event.stopPropagation()"
                    color="accent">
                    <mat-label>Location suchen</mat-label>
                    <input
                        matInput
                        (click)="$event.stopPropagation()"
                        formControlName="locationSearch" />
                </mat-form-field>
                <mat-option
                    *ngFor="let location of locationOptions | async"
                    [value]="location"
                    (click)="setSelectedLocation(location)"
                    >{{ location.name }}</mat-option
                >
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="standard">
            <mat-label>Shop</mat-label>
            <mat-select formControlName="shop" [compareWith]="compareWithFn">
                <mat-form-field
                    appearance="standard"
                    (click)="$event.stopPropagation()"
                    color="accent">
                    <mat-label>Laden suchen</mat-label>
                    <input
                        matInput
                        (click)="$event.stopPropagation()"
                        formControlName="shopSearch" />
                </mat-form-field>
                <mat-option
                    *ngFor="let shop of shopOptions | async"
                    [value]="shop"
                    (click)="setSelectedShop(shop)"
                    >{{ shop.name }}</mat-option
                >
            </mat-select>
        </mat-form-field>
        <mat-button-toggle-group
            formControlName="tradeType"
            class="toggle-group">
            <mat-button-toggle [value]="CommodityTradeType.buy">
                Ich kaufe
                <div class="sh-top-corners"></div>
                <div class="sh-bottom-corners"></div>
            </mat-button-toggle>
            <mat-button-toggle [value]="CommodityTradeType.sell">
                Ich verkaufe
                <div class="sh-top-corners"></div>
                <div class="sh-bottom-corners"></div>
            </mat-button-toggle>
        </mat-button-toggle-group>
        <mat-form-field appearance="standard">
            <mat-label>Handelsware</mat-label>
            <mat-select formControlName="commodityId">
                <mat-form-field
                    appearance="standard"
                    (click)="$event.stopPropagation()"
                    color="accent">
                    <mat-label i18n="@@search_commodity">Ware suchen</mat-label>
                    <input
                        matInput
                        (click)="$event.stopPropagation()"
                        formControlName="commoditySearch" />
                </mat-form-field>
                <mat-option
                    *ngFor="let commodity of commodityOptions | async"
                    [value]="commodity.id"
                    (click)="setSelectedCommodity(commodity)"
                    >{{ commodity.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field
            appearance="standard"
            style="position: relative"
            [shTooltip]="priceTooltip">
            <span
                *ngIf="selectedShopItemPriceEntry"
                style="
                    position: absolute;
                    left: 50%;
                    top: -45px;
                    transform: translateX(-50%);
                    color: #9ca4a8;
                    opacity: 0.7;
                    text-align: center;
                    display: inline-block;
                    width: 100%;
                "
                >Aktueller Preis
                {{ selectedShopItemPriceEntry?.pricePerItem * 100 }} aUEC
            </span>
            <mat-label i18n="@@price_per_unit">Preis für 1 SCU</mat-label>
            <input type="number" matInput formControlName="pricePerItem" />
        </mat-form-field>
        <span *ngIf="isPriceDeviation" class="validation-error"
            >Der eingegebene Preis weicht stark vom aktuellen Preis ab. Bist du
            dir wirklich sicher? Es kann sein, dass der Preis dann erst manuell
            von einem Mod verifiziert werden muss.</span
        >
    </form>
</sh-content-container>

<ng-template #priceTooltip>
    <div class="sh-tooltip">
        <div>
            <h4 class="tooltip-title">Achtung</h4>
            <div class="tooltip-description">
                Aktuell gibt es einen Fehler bei der Anzeige des Preises. Der
                Preis pro Unit ist eigentlich der Preis pro SCU (siehe
                Screenshot). Bitte achte darauf, dass du den Preis pro SCU
                einträgst (roter Rahmen).
            </div>
        </div>
        <img src="assets/images/priceToolTooltip.png" class="tooltip-image" />
    </div>
</ng-template>
