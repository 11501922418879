import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sh-grid-list-tile',
  templateUrl: './grid-list-tile.component.html',
  styleUrls: ['./grid-list-tile.component.scss']
})
export class GridListTileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
