import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor() { }

  public getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLength = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLength; i++) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  public deleteCookie(cookieName: string) {
    this.setCookie({ name: cookieName, value: '', expireDays: -1 });
  }

  /**
   * Expores default 1 day
   * If params.session is set and true expires is not added
   * */
  public setCookie(params: any) {
    let d: Date = new Date();
    d.setTime(
      d.getTime() + (params.expireDays ? params.expireDays : 1) * 24 * 3600 * 1000
    );
    document.cookie =
      (params.name ? params.name : '') +
      '=' +
      (params.value ? params.value : '') +
      ';' +
      (params.session && params.session == true ? '' : 'expires=' + d.toUTCString() + ';') +
      'path=' +
      (params.path && params.path.length > 0 ? params.path : '/') +
      ';' +
      (location.protocol === 'https:' && params.secure && params.secure == true ? 'secure' : '');
  }
}
