import {ContentChildren, Directive, Input, QueryList} from '@angular/core';
import {MenuItemComponent} from '@starhead/core/components/menu-item/menu-item.component';

@Directive({
  selector: '[shNavMenu]'
})
export class NavMenuDirective {
  @Input() onlyOneOpen = false;
  @ContentChildren(MenuItemComponent) items: QueryList<MenuItemComponent> | undefined;

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterContentInit(): void {
    console.log('INIT MENU', this.items.toArray());
    this.items.forEach(item => {
      item.open = false;

    });
  }

  closeOthers(opened: MenuItemComponent): void {
    console.log('Close others');
    for (const item of this.items) {
      if (opened !== item && item.open) {
        item.open = false;
      }
    }
  }

  closeAll(): void {
    console.log('Close All');
    for (const item of this.items) {
      item.open = false;
    }
  }

}
