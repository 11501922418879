import { Injectable } from '@angular/core';
import { forkJoin, ReplaySubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Organization } from '@starhead/entities/organization';
import { Vehicle } from '@starhead/entities/vehicle/vehicle';
import { CelestialObject } from '@starhead/entities/celestial-objects/celestial-object';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class StarHeadService {
  private routeSource$ = new Subject<string>();
  route = this.routeSource$.asObservable();

  baseUrl = environment.apiUrl;

  private vehicleSource = new ReplaySubject<Vehicle[]>(1);
  vehicles$ = this.vehicleSource.asObservable();
  private organizationSource = new ReplaySubject<Organization[]>(1);
  organizations$ = this.organizationSource.asObservable();
  private celestialObjectSource = new ReplaySubject<CelestialObject[]>(1);
  celestialObjects$ = this.celestialObjectSource.asObservable();

  constructor(private http: HttpClient) {
    this.init();
  }

  setCurrentRoute(url: string): void {
    switch (url) {
      case 'trading':
        this.routeSource$.next('tools');
        break;
      case 'mining':
        this.routeSource$.next('tools');
        break;
      default:
        this.routeSource$.next(url);
        break;
    }
  }

  init(): void {
    forkJoin({
      veh: this.http.get<Vehicle[]>(this.baseUrl + '/vehicle'),
      org: this.http.get<Organization[]>(this.baseUrl + '/organization'),
      co: this.http.get<CelestialObject[]>(this.baseUrl + '/celestialObject')
    }).subscribe((results) => {
      this.celestialObjectSource.next(results.co);
      this.organizationSource.next(results.org);
      this.mapVehivles(results.veh);
    }, (error) => {
      console.error('Error in StarHead-Service: ' + error.message, error);
    });
  }

  mapVehivles(vehicles: Vehicle[]) {
    vehicles.map(v => {
      let pattern = new RegExp(/\s+/g);
      v.slug = v.name.replace(pattern, '_').toLowerCase();
    });
    this.vehicleSource.next(vehicles);
  }

  async getOrganizations(id: number): Promise<Organization> {
    let org: Organization;
    await this.http.get<Organization[]>(this.baseUrl + '/organization').pipe(
      map((result) => {
        org = result.find((o) => o.id === id);
        return org;
      })
    );
    return org;
  }
}
