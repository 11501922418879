<li class="list-group-item sh-list-item-mb-5" (click)="toggleSelection()">
  <div class="row-cols-3">
    <img src="assets/icons/icon_commodities.png" class="sh-h30px me-2 sh-w-auto">
    <label class="form-check-label col sh-label" style="line-height: 1rem;">{{model.name}}</label>

    <input type="checkbox" value="" [id]="'commodityListitem' + model.id" [(ngModel)]="model.selected"
           class="form-check-input col float-end">
  </div>
</li>
<mat-selection-list #commoditySelection [multiple]="false">

</mat-selection-list>
