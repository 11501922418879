import { Component, ViewChild } from "@angular/core";
import { Navigation, NavigationEnd, Router } from "@angular/router";
import {StarHeadService} from '@starhead/service/star-head.service';
import { CelestialObjectService } from "@starhead/service/celestial-object.service";
import { CookieService } from "@starhead/service/cookie.service";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { AccountService } from "@starhead/service/account.service";
import { FeedbackComponent } from "@starhead/components/feedback/feedback.component";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { environment } from "../environments/environment";

@Component({
  selector: 'sh-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'star-head';
  currentRoute: string;
  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor(private router: Router,
              private starHeadService: StarHeadService,
              private coService: CelestialObjectService,
              private cookieService: CookieService,
              public accountService: AccountService,
              public dialog: MatDialog,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) {
    this.starHeadService.route.subscribe(route => {
      this.currentRoute = route;
    });
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.starHeadService.setCurrentRoute(e.url);
      }
    });

    coService.getAllCelestialObjects();

    matIconRegistry.addSvgIcon('ships', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/navigation/outline.svg'));
    matIconRegistry.addSvgIcon('planet', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/navigation/planet.svg'));
    matIconRegistry.addSvgIcon('location', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/navigation/location.svg'));
    matIconRegistry.addSvgIcon('shops', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/navigation/shops.svg'));
    matIconRegistry.addSvgIcon('cargo_route', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/navigation/cargo_route.svg'));
  }

  openFeedbackDialog(): void {
    const dialogRef = this.dialog.open(FeedbackComponent, {
      width: '400px',
    });
    this.sidenav.close();
  }
}
