import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import {ServerStatusService} from '@starhead/service/server-status.service';
import {LanguageService} from '@starhead/service/language.service';
import { AccountService } from "@starhead/service/account.service";



@Component({
  selector: 'sh-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {
  @ViewChild('accountSidebar') sidebar: ElementRef;
  loggedIn: boolean = false;
  @Output() public sidenavToggle = new EventEmitter();

  accountBtnClass = 'sh-btn sh-btn-outline-accent sh-account-btn';
  tooltipText = '';

  constructor(private serverStatus: ServerStatusService, private langService: LanguageService, private accountService: AccountService) { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    // do nothing
    this.accountService.loggedIn$.subscribe((x) => {
      this.loggedIn = x;
      this.accountBtnClass = this.loggedIn ? 'sh-btn sh-btn-outline-accent sh-account-btn loggedin' : 'sh-btn sh-btn-outline-accent sh-account-btn loggedout';
      this.tooltipText = this.loggedIn ? 'Angemeldet' : 'Abgemeldet';
    });

  }

  onLoginClick(): void {
    window.alert('Die loginfunktion ist zur Zeit nicht verfügbar.');
  }

  onLanguageChange(lang: string): void {
    this.langService.setLanguage(lang);
  }


  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }
}
