<nav class="navbar navbar-expand-lg navbar-dark sh-bg-primary sh-color-text--light" id="sh-top-bar">
  <div class="container-fluid">
    <a routerLink="/" class="navbar-brand">
      <img src="../../../../assets/images/logo/LOGO_@290.png" class="sh-logo" alt="star head logo">
    </a>
    <div fxHide.gt-sm>
      <button mat-icon-button (click)="onToggleSidenav()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
<!--    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#shMainNav">-->
<!--      <span class="navbar-toggler-icon"></span>-->
<!--    </button>-->
    <div class="collapse navbar-collapse" id="shMainNav">
      <div class="ml-10" hidden>
        <form class="d-flex">
          <input class="me-2 sh-input" type="search" placeholder="Suche nach Guides, News etc."
                 aria-label="Search">
          <button class="sh-btn sh-btn-outline-success" type="submit">Suchen</button>
        </form>
      </div>

      <div class="navbar-nav mb-2 mb-lg-0 me-auto">

      </div>
      <div class="d-flex align-items-center mb-2 mb-lg-0 text-decoration-none">
        <button (click)="onToggleSidenav()"
                [className]="accountBtnClass">
          Account
        </button>
      </div>
      <div class="sh-divider-vertical ml-1 mr-1"></div>
      <div class="d-flex align-items-center mb-2 mb-lg-0 text-decoration-none">
        <button mat-stroked-button color="info" [matMenuTriggerFor]="langMenu" i18n="@@lblLanguage">Sprache</button>
        <mat-menu #langMenu="matMenu">
          <button class="sh-btn sh-btn-accent sh-w100" style="margin-bottom: 5px;" (click)="onLanguageChange('de')">DE</button>
          <button class="sh-btn sh-btn-accent sh-w100" (click)="onLanguageChange('en')">EN</button>
        </mat-menu>
      </div>
    </div>
  </div>
</nav>
<div class="offcanvas offcanvas-end sh-bg-main" tabindex="1" id="accountSidebar"
     aria-labelledby="accountSidebar" role="dialog">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasLabel">Account</h5>
    <button type="button" class="btn-close sh-text-light" data-bs-dismiss="offcanvas"><mat-icon>close</mat-icon></button>
  </div>
  <div class="offcanvas-body">
    <sh-sidebar-account></sh-sidebar-account>
    <sh-sidebar-admin-access></sh-sidebar-admin-access>
  </div>
</div>
