import { Directive, ElementRef, OnInit } from "@angular/core";
import { NgModel } from "@angular/forms";
import { getLocaleNumberFormat, NumberFormatStyle } from "@angular/common";

@Directive({
  selector: '[shDecimalConvert]'
})
export class DecimalConvertDirective implements OnInit {

  constructor(private ref: ElementRef, private ngModel?: NgModel) { }

  ngOnInit() {

    this.ngModel.valueChanges.subscribe(value => {
      this.ref.nativeElement.locale = getLocaleNumberFormat('en', NumberFormatStyle.Currency);
    });
  }
}
