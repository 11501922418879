<mat-card class="sh-ship-container sh-card-dark">
  <div class="d-flex flex-column mt-3">
    <div class="d-flex justify-content-between align-items-start pointer">
      <div class="d-flex flex-column mat-display-2 sh-ship-name">
        <b>{{selectedShip?.name}}</b>
      </div>
      <span class="mx2"></span>
      <img class="sh-manufacturer-select-logo" src="assets/logos/manufacturers/{{selectedShip?.manufacturer?.code}}_WHITE.png">
    </div>
    <div class="position-relative mt-2 pointer">
      <img [src]="imageUrl" class="sh-vehicle-thumbnail" height="100" width="auto">
    </div>
  </div>
</mat-card>
