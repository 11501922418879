import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { environment } from "../../environments/environment";
import { LocationType } from "@starhead/entities/location/location-type";
import { LocationUpdateDto } from "@starhead/core/dtos/location-update-dto";
import { Location } from "@starhead/entities/location";

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private baseUrl = environment.apiUrl + '/location';

  private locationSource$ = new Subject<Location[]>();
  locations$ = this.locationSource$.asObservable();

  private locationCache = new Map();

  constructor(private http: HttpClient) {
    this.getAllLocations().subscribe((result) => {
      this.locationCache.set(Object.values('allLocations').join('-'), result);
      this.locationSource$.next(result);
    });
  }

  getAllLocations(celestialParentFilter?: number, typeFilter?: number): Observable<Location[]> {
    let query = this.baseUrl;

    if (celestialParentFilter || typeFilter) {
      query += '?';
    }
    if (celestialParentFilter) {
      query += `celestialParentFilter=${celestialParentFilter}&`;
    }
    if (typeFilter) {
      query += `typeFilter=${typeFilter}`;
    }

    return this.http.get<Location[]>(query);
  }

  getLocationById(id: number): Observable<Location> {
    return this.http.get<Location>(this.baseUrl + `/${id}`);
  }

  getLocationTypes(): Observable<LocationType[]> {
    return this.http.get<LocationType[]>(this.baseUrl + '/type');
  }

  createLocationType(newLocation: Location): Observable<Location> {
    return this.http.post<Location>(this.baseUrl, newLocation);
  }

  updateLocation(location: LocationUpdateDto): Observable<Location> {
    return this.http.put<Location>(this.baseUrl, location);
  }
}
