import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormControl } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { KeycloakService } from "keycloak-angular";
import { DatePipe } from "@angular/common";
import { LoggerService } from "@starhead/service/logger.service";
import { Platform } from "@angular/cdk/platform";

@Component({
  selector: 'sh-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  contentControl = new FormControl('', {updateOn: "change"});
  usernameControl = new FormControl('');
  topicsControl = new FormControl('- Bitte wählen -');
  imageControl = new FormControl('');
  textAreaLength = 1000;
  maxLength = 1000;
  feedbackForm = this.fb.group({
    content: this.contentControl,
    username: this.usernameControl,
    topics: this.topicsControl,
    imageUpload: this.imageControl
  });

  @ViewChild('counter') counter: ElementRef;
  @ViewChild('imgSrc') imgSrc: ElementRef<HTMLImageElement>;

  dateOptions = {weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric'};

  webHook: string = 'https://discord.com/api/webhooks/983799498440572938/uGJ0LOagBlFUhrY_OQBAGPq5C1Vz1QXOteBWkona9VGXmiKJObmjQxR55s7zIcc9EEHZ';
  content: string = '';
  topics: string[] = [
    'Bug',
    'Routenplaner',
    'Warenübersicht',
    'Design',
    'Account'
  ];
  selectedTopic: string;
  username: string = '';
  imageFile: any;
  imageFileName: string;
  currentBrowser: string;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<FeedbackComponent>,
    private keycloak: KeycloakService,
    private datePipe: DatePipe,
    private elementRef: ElementRef,
    private platform: Platform,
    private logger: LoggerService
  ) {
  }

  ngOnInit(): void {
    this.username = this.toTitleCase(this.keycloak.getUsername());
    this.usernameControl.setValue(this.username);
    this.imageControl.registerOnChange((img) => {
      this.dropped(img);
    });
    this.dialogRef.addPanelClass('sh-dialog-container');
    this.currentBrowser = this.getBrowserName(navigator.userAgent);
  }

  charCounter() {
    if(this.contentControl.value.length >= this.maxLength) {
      this.contentControl.patchValue(this.contentControl.value.substring(0, this.maxLength));
      return false;
    } else {
      this.textAreaLength = this.maxLength - this.contentControl.value.length;
    }
  }

  dropped(files: any) {
    this.imageFile = files.target.files[0];
    this.imageFileName = this.imageFile.name;
    const blob = URL.createObjectURL(this.imageFile);
    this.imgSrc.nativeElement.src = blob;
    this.imgSrc.nativeElement.width = 50;
    this.imgSrc.nativeElement.height = 50;
  }

  sendFeedback() {
    const date = Date.now();
    const dateFormatted = this.datePipe.transform(date, 'yyyy-MM-ddTHH:mm:ss');
    console.log(dateFormatted);
    let feedback = {
      content: null,
      embeds: [
        {
          title: `Bereich: ${this.topicsControl.value}`,
          description: this.contentControl.value,
          color: 56049,
          "fields": [
            {
              name: "Verwendeter Browser",
              value: this.currentBrowser
            }
          ],
          footer: {
            text: this.username,
          },
          timestamp: dateFormatted,
        },
      ],
      username: 'StarHead Feedback',
      avatar_url: 'https://test.star-head.de/favicon.ico',
      attachments: [],
    };

    const fd = new FormData();
    fd.set('payload_json', JSON.stringify(feedback));
    if(this.imageFile) {
      fd.set('file1', this.imageFile);
    }

    this.http.post(this.webHook, fd, {observe: "response"}).subscribe(result => {
      if(result.status == 204) {
        this.toastr.success('Vielen Dank für dein Feedback.', 'Erfolgreich');
      }
      this.dialogRef.close();
    }, error => {
      console.log(error);
      this.toastr.error('Beim senden des Feedback ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.', 'Fehler');
    });

    // this.http.post(this.webHook, feedback, {observe: "response"}).subscribe(result => {
    //   if(result.status == 204) {
    //     this.toastr.success('Vielen Dank für dein Feedback.', 'Erfolgreich');
    //   }
    //   this.dialogRef.close();
    // }, error => {
    //   console.log(error);
    //   this.toastr.error('Beim senden des Feedback ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.', 'Fehler');
    // });
  }

  getBrowserName(userAgent) {
    let browserName = '';

    if(userAgent.match(/edg/i)){
      browserName = "Edge";
    }else if(userAgent.match(/chrome|chromium|crios/i)){
      browserName = "Chrome";
    }else if(userAgent.match(/firefox|fxios/i)){
      browserName = "Firefox";
    }  else if(userAgent.match(/safari/i)){
      browserName = "Safari";
    }else if(userAgent.match(/opr\//i)){
      browserName = "Opera";
    } else {
      browserName="No browser detection";
    }
    this.logger.debugWithId(
      'Verwendeter browser: ',
      FeedbackComponent.name,
      this.getBrowserName.name,
      browserName, userAgent
    );

    return browserName;
  }

  toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

}
