import { Injectable } from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import { ServerStatus } from '@starhead/core/entities/server-status';

@Injectable({
  providedIn: 'root',
})
export class ServerStatusService {

  constructor() {
    // empty
  }

  getStatus(stage: string = 'pu | ptu'): Observable<ServerStatus>  {
    switch (stage) {
      case 'pu':
        return of( {server: 'online', version: '3.13.0', testgroup: 'none'});
      case 'ptu':
        return of({server: 'online', version: '3.13.1', testgroup: 'All Backers'});
    }
  }
}
