import {Injectable, EventEmitter} from '@angular/core';
import {NavMenuDirective} from '@starhead/core/directives/nav-menu.directive';

@Injectable()
export class ShDropdownState {
  autoClose = true;
  insideClick = true;
  isAnimated = true;
  isOpen = false;
  isOpenChange = new EventEmitter<boolean>();
  isDisabledChange = new EventEmitter<boolean>();
  toggleClick = new EventEmitter<boolean>();

  constructor() {
  }
}
