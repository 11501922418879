import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Commodity } from '@starhead/entities/trading/commodity';
import { Shop } from '@starhead/entities/shops/shop';
import { ShopItemPriceDto } from '@starhead/core/dtos/shop-item-price-dto';
import { ShopService } from '@starhead/service/tradingServices/shop.service';
import { CommodityTradeType } from '@starhead/core/interfaces/enums/commodity-trade-type';
import { CommodityService } from '@starhead/service/commodity.service';
import { ToastrService } from "ngx-toastr";
import { ShopItemPriceEntry } from "@starhead/entities/shops/shop-item-price-entry";
import { ShopItemTransferDto } from "@starhead/core/dtos/shop-item-transfer-dto";

@Component({
  selector: 'sh-price-item-tool',
  templateUrl: './price-item-tool.component.html',
  styleUrls: ['./price-item-tool.component.scss'],
})
export class PriceItemToolComponent implements OnInit, OnChanges {
  commodities: Commodity[];
  shops: Shop[];
  priceEntries: ShopItemPriceDto[] = [];
  priceTransferDtos: ShopItemTransferDto[] = [];
  lastPriceEntry: ShopItemTransferDto;

  constructor(
    private shopService: ShopService,
    private commodityService: CommodityService,
    private toastr: ToastrService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log(`changes: ${JSON.stringify(changes)}`);
  }

  ngOnInit(): void {
    this.loadShops();
    this.loadCommodities();
    this.initializeForm();
  }

  initializeForm() {
    this.addPriceEntry();
  }

  addPriceEntry() {
    const lastEntry = this.priceTransferDtos[this.priceTransferDtos.length -1];

    if(lastEntry) {
      this.lastPriceEntry = lastEntry;
      this.priceTransferDtos.push({
        celestialObject: this.lastPriceEntry.celestialObject,
        location: this.lastPriceEntry.location,
        shop: lastEntry.shop,
        pricePerItem: null,
        tradeType: lastEntry.tradeType,
        commodityId: 0,
        internalShopInventoryNodeId: lastEntry.internalShopInventoryNodeId,
        refreshScuPerMinute: lastEntry.refreshScuPerMinute,
        maxInventoryScu: lastEntry.maxInventoryScu,
        maxInventoryCscu: lastEntry.maxInventoryCscu
      });
      console.log(lastEntry);
    } else {
      this.priceTransferDtos.push({
        celestialObject: null,
        location: null,
        shop: null,
        commodityId: 0,
        pricePerItem: null,
        tradeType: CommodityTradeType.buy,
        maxInventoryScu: 0,
        maxInventoryCscu: 0,
        refreshScuPerMinute: 0,
        internalShopInventoryNodeId: '',
      });
    }
  }

  resetPriceEntries() {
    this.priceEntries = [];
    this.priceTransferDtos = [];
    this.addPriceEntry()
  }

  onRemoveEntry(index: number) {
    this.priceTransferDtos.splice(index, 1);
  }

  deletePriceEntry(index: number) {
    this.priceEntries.splice(index, 1);
  }

  savePrices() {
    console.log(`Speichere Preise: ${JSON.stringify(this.priceEntries)}`);
    this.priceTransferDtos.map(x => {
      this.priceEntries.push({
        pricePerItem: x.pricePerItem / 100,
        shopId: x.shop.id,
        internalShopInventoryNodeId: x.internalShopInventoryNodeId,
        refreshScuPerMinute: x.refreshScuPerMinute,
        maxInventoryScu: x.maxInventoryScu,
        maxInventoryCscu: x.maxInventoryCscu,
        commodityId: x.commodityId,
        tradeType: x.tradeType
      })
    });
    this.shopService.addOrUpdateShopItemPrice(this.priceEntries).subscribe(response => {
      this.toastr.success('Die Preise wurden erfolgreich gespeichert.');
      this.resetPriceEntries();
    }, error => {
      if(error) {
        this.toastr.error('Fehler beim speichern der Preise.', 'Fehlgeschlagen');
      }
    });
  }

  loadShops() {
    this.shopService.getShops().subscribe((result) => {
      this.shops = result;
    });
  }

  loadCommodities() {
    this.commodityService.getCommodityList().subscribe((result) => {
      this.commodities = result;
    });
  }

  patchPriceEntry(value: any, priceEntry: ShopItemTransferDto, index: number) {
    this.priceTransferDtos[index] = priceEntry;
  }
}
