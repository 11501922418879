import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable, Subject} from 'rxjs';
import {Commodity} from '@starhead/core/entities/trading/commodity';
import {HttpClient} from '@angular/common/http';
import { LoggerService } from "@starhead/service/logger.service";

@Injectable({
  providedIn: 'root'
})
export class CommodityService {
  baseUrl = environment.apiUrl + '/commodity';
  private commoditySource = new Subject<Commodity[]>();
  commodities$ = this.commoditySource.asObservable();
  commodityCache = new Map<string, Commodity[]>();

  constructor(private http: HttpClient, private logger: LoggerService) { }

  getCommodityList(): Observable<Commodity[]> {
   this.http.get<Commodity[]>(this.baseUrl).subscribe(res => {
      this.commoditySource.next(res);
      this.logger.debugWithId('Commodity result:', CommodityService.name, this.getCommodityList.name, res);
      this.commodityCache.set(Object.values('commodities').join('-'), res);
    });
    return this.commodities$;
  }
}
