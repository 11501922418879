import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

@Component({
  selector: 'sh-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss']
})
export class RangeSliderComponent implements OnInit, AfterViewInit {

  @Input() min: number;
  @Input() max: number;
  @Input() value: number = 0;
  @Input() step: number = 1;
  @Input() customCSSClass: string;
  @Input() suffix: string = '';
  @Input() prefix: string = '';
  @Output() valueChange = new EventEmitter<number>();
  @ViewChild('shRangeSlider') rangeSlider: ElementRef;

  currentValue: number;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.rangeSlider.nativeElement.input = this.handleInput;
  }

  handleInput(e) {
    this.valueChange.emit(e.target.valueAsNumber);
    if(e.target.valueAsNumber > this.max) {
      this.max = e.target.valueAsNumber + (e.target.valueAsNumber * 0.1);
    }
  }

  increase() {
    if(this.value + +this.step > this.max) return;
    this.value += +this.step;
    this.valueChange.emit(this.value);
  }

  decrease() {
    if(this.value - this.step < this.min) return;
    this.value -= this.step;
    this.valueChange.emit(this.value);
  }

}
