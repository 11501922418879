import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sh-grouped-dropdown',
  templateUrl: './grouped-dropdown.component.html',
  styleUrls: ['./grouped-dropdown.component.scss']
})
export class GroupedDropdownComponent implements OnInit {

  constructor() { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    // do nothing
  }

}
