import { Component, OnInit } from '@angular/core';
import {KeycloakBearerInterceptor, KeycloakEvent, KeycloakService} from 'keycloak-angular';
import Keycloak, {KeycloakLoginOptions} from 'keycloak-js';
import {AccountService} from '@starhead/service/account.service';
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: 'sh-sidebar-account',
  templateUrl: './sidebar-account.component.html',
  styleUrls: ['./sidebar-account.component.scss']
})
export class SidebarAccountComponent implements OnInit {
  isLoggedIn = false;
  userProfile: Keycloak.KeycloakProfile | null = null;
  token = '';
  kEvents: KeycloakEvent;
  loginForm: FormGroup;

  constructor(private keycloak: KeycloakService, private accountService: AccountService) {
  }

  async ngOnInit(): Promise<void> {
    const k = this.keycloak.getKeycloakInstance();
    this.loginForm = new FormGroup({
      username: new FormControl(''),
      password: new FormControl('')
    });

    if (k.authenticated) {
      this.userProfile = await this.keycloak.loadUserProfile();
      this.isLoggedIn = k.authenticated;
      const roles = this.keycloak.getUserRoles();
      this.token = k.token;
    }

  }

  loginWithCredentials(): void {

  }

  onSignupClick(form: any): void {
    const username = this.loginForm.controls['username'].value;
    const password = this.loginForm.controls['password'].value;
    // this.accountService.loginWithCredentials('metascallion', 'Tld!468513#2021');
    this.accountService.loginIn();
  }

  onEditProfileClick(): void {
    this.accountService.editUserProfile();
  }

  onLogoutClick(): void {
    this.keycloak.logout();
  }

  register(): void {
    this.accountService.register();
  }

  onLoginWithCredentials(username: string, password: string): void {
    // this.accountService.loginWithCredentials(username, password);
  }

}
