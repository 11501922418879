import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { QuestFormComponent } from '@starhead/core/components/quest-form/quest-form.component';
import { ArticleService } from '@starhead/core/services/article.service';

@Component({
  selector: 'sh-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  bsModalRef: BsModalRef;
  questFormData: any;
  sliderValue = 0;
  articles: any;

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private modalService: BsModalService,
    private articelService: ArticleService
  ) { }

  ngOnInit(): void {
    const title = this.route.snapshot.data.title;
    this.titleService.setTitle(title);

    this.articelService.getArticles().subscribe(res => {
      this.articles = res;
      console.log(this.articles);
    });
  }

  changeSliderValue(value) {
    console.log(value);
    this.sliderValue =  value;
  }

  openFormModal(type: string): void {
    this.bsModalRef = this.modalService.show(QuestFormComponent, {backdrop: 'static'});
    this.bsModalRef.content.loadFormData(type);
    this.bsModalRef.onHide.subscribe(ref => {
      this.questFormData = this.bsModalRef.content.formData;
      console.log(this.questFormData);
    });
  }
}
