<section class="sh-shopitem-header">
  <img [src]="iconSrc" (error)="iconSrc = defaultIcon" class="sh-shopitem-header--icon">
  <div class="sh-shopitem-header--container__center">
    <h1 class="sh-shopitem-header--title">{{commodityName}}</h1>
    <span class="sh-shopitem-header--inventory__label">max inventory <img height="10px"
                                                                          src="/assets/icons/arrows_right.svg"></span>
  </div>
  <div class="sh-shopitem-header--container__right">
    <span
      class="sh-shopitem-header--inventory__amount">{{maxInventory | scCargo:'cSCU'}}</span>
    <span class="sh-shopitem-header--price">{{pricePerUnit | scCurrency: ''}} / UNIT</span>
  </div>
</section>
