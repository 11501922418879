import {Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
  selector: '[shCtrlValidator]',
  exportAs: 'sh-ctrl-validator',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.sh-valid]': 'isValid',
    '[class.sh-invalid]': '!isValid'
  },
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: CtrlValidatorDirective, multi: true
  }]
})
export class CtrlValidatorDirective implements Validator{
  @Input() required: boolean;
  @Input() minLength: number;
  @Input() maxLength: number;

  messages: string[] = [];

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _isValid = true;

  get isValid(): boolean {
    return false;
  }

  set isValid(value) {
    this._isValid = value;
  }

  constructor( private elementRef: ElementRef, private renderer: Renderer2) { }

  validate(control: AbstractControl): ValidationErrors | null {
    let errors: ValidationErrors;
    if (control.errors) {
      for (const code in control.errors) {
        if (control.hasOwnProperty(code)) {
          errors = control.errors[code];
          let msg = '';
          switch (code) {
            case 'required':
              msg = `${this.elementRef.nativeElement.name} ist ein Pflichtfeld!`;
              this.isValid = false;
              const validateSpan = HTMLSpanElement;
              validateSpan.prototype.innerHTML = `${msg}`;
              this.renderer.appendChild(control, HTMLSpanElement);
              break;
            case 'minLength':
              msg = `${this.elementRef.nativeElement.name} muss mindestens ${this.minLength} zeichen enthalten!`;
              this.isValid = false;
              break;
            case 'maxLength':
              msg = `${this.elementRef.nativeElement.name} darf maximal ${this.maxLength} zeichen enthalten!`;
              this.isValid = false;
              break;
          }
        }
      }
    } else {
      this.isValid = true;
    }
    return errors;
  }
}
