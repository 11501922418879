import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'sh-shop-item-header',
  templateUrl: './shop-item-header.component.html',
  styleUrls: ['./shop-item-header.component.scss']
})
export class ShopItemHeaderComponent implements OnInit {
  @Input() commodityName: string;
  @Input() maxInventory: number;
  @Input() pricePerUnit: number;
  @Input() iconSrc: string;
  defaultIcon: string;

  constructor() { }

  ngOnInit(): void {
    this.iconSrc = this.iconSrc.replace(/\s/g, '');
    this.defaultIcon = '/assets/icons/commodities/Inv_Icon_Default.svg';
  }

}
