import {Component, Directive, Input, OnInit, EventEmitter, ContentChildren, Output} from '@angular/core';

@Directive({
  selector: '[shMenuTitle]'
})
export class MenuTitleDirective {}
@Component({
  selector: 'sh-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {
  open = false;
  @Input() title: any;
  @Output() menuToggled = new EventEmitter();
  @ContentChildren(MenuTitleDirective) menuTitle: MenuTitleDirective | undefined;

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    // do nothing
  }

  hasTitle(): boolean {
    return this.menuTitle != null;
  }

  toggleMenu(): void {
    this.open = !this.open;
    this.menuToggled.emit(this);
  }

  openMenu(): void {
    this.open = true;
    this.menuToggled.emit(this);
  }

  closeMenu(): void {
    this.open = false;
    this.menuToggled.emit(this);
  }

  get
  isOpen(): boolean {
    return this.open;
  }
}
