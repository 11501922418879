import { Directive, TemplateRef, ViewContainerRef, Input, HostListener, ElementRef } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

@Directive({
  selector: '[shTooltip]'
})
export class TooltipDirective {
  private overlayRef: OverlayRef;
  private _template: TemplateRef<any>;

  @Input()
  set shTooltip(template: TemplateRef<any>) {
    this._template = template;

    if (this.overlayRef) {
      this.overlayRef.dispose();
    }

    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.el.nativeElement)
        .withPositions([
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'top'
          }
        ]),
        scrollStrategy: this.overlay.scrollStrategies.close()
    });
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.overlayRef.attach(new TemplatePortal(this._template, this.viewContainerRef));
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.overlayRef.detach();
  }

  constructor(private overlay: Overlay, private viewContainerRef: ViewContainerRef, private el: ElementRef) {}
}