<div class="ml-10">
  <div class="status left" triggers="mouseenter:mouseleave">
    <span>PU</span>
  </div>
  <div class="status middle" triggers="mouseenter:mouseleave">
    <span>PTU</span>
  </div>
</div>
<ng-template #puStatusTemplate>Persistent Universe<div [innerHTML]="puStatusHtml"></div></ng-template>
<ng-template #ptuStatusTemplate>Public Test Universe<div [innerHTML]="ptuStatusHtml"></div></ng-template>
