import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { Commodity } from "@starhead/entities/trading/commodity";

@Component({
  selector: 'sh-commodity-list-item',
  templateUrl: './commodity-list-item.component.html',
  styleUrls: ['./commodity-list-item.component.scss']
})
export class CommodityListItemComponent implements OnInit {
  @Input() model: any;
  @Output() selected = new EventEmitter<Commodity>();

  constructor() { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    // do nothing
  }

  toggleSelection() {
    this.model.selected = !this.model.selected
    if (this.model.selected) {
      this.selected.emit(this.model);
    }
  }

}
