import { Injectable } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { delay, timeout } from "rxjs/operators";
import { LoggerService } from "@starhead/service/logger.service";

@Injectable({
  providedIn: 'root'
})
export class BusyService {
  busyRequestCount = 0;

  constructor(private spinnerService: NgxSpinnerService, private logger: LoggerService) { }

  busy() {
    this.busyRequestCount++;
    this.spinnerService.show(undefined, {
      type: 'ball-grid-beat',
      bdColor: 'rgba(0, 0, 0, 0.8)',
      color: '#18d6d2',
      size: 'medium',
    });
  }

  idle() {
    this.busyRequestCount--;
    if(this.busyRequestCount <= 0) {
      this.busyRequestCount = 0;
      this.spinnerService.hide();
    }
  }
}
