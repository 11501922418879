<ngx-spinner
  bdColor = "rgba(0, 0, 0, 0.8)"
  size = "medium"
  color = "#00daf1"
  template="<img class='image' src='https://files.star-head.de/images/LOGO_STERN_@256.png' width='128' height='128'>"
  type = "ball-grid-beat"
  [fullScreen] = "true">
  <h3 style="color: white" > Wird geladen... </h3>
</ngx-spinner>

<mat-sidenav-container class="sh-h100vh">
  <mat-sidenav #sidenav role="navigation" position="end">
    <sh-content-container heading="Account">
      <button mat-icon-button (click)="sidenav.close()"><mat-icon>close</mat-icon></button>
      <sh-sidebar-account class="mb-3"></sh-sidebar-account>
      <sh-sidebar-admin-access [sidenav]="sidenav"></sh-sidebar-admin-access>
      <section fxHide.gt-sm>
        <h5>Navigation</h5>
        <mat-nav-list>
          <mat-list-item>
            <button mat-button [routerLink]="['/']" [class.sh-active]="currentRoute === ''" (click)="sidenav.close()">
              Startseite</button>
          </mat-list-item>
          <mat-list-item>
            <button mat-button [matMenuTriggerFor]="tools">Tools</button>
            <mat-menu #tools="matMenu" xPosition="before">
              <div type="button" class="sh-cdk-menu-item sh-menu-bar-item">
                <div class="sh-btn-nav sh-cdk-nav-link" [routerLink]="['trading/routes']" (click)="sidenav.close()">
                  <span style="transform: skew(15deg)">Routenplaner</span>
                </div>
              </div>
              <div class="sh-cdk-menu-item sh-menu-bar-item">
                <div type button class="sh-btn-nav sh-cdk-nav-link" [routerLink]="['trading/commodities']" (click)="sidenav.close()">
                  Waren übersicht
                </div>
              </div>
            </mat-menu>
          </mat-list-item>
          <mat-list-item>
            <button mat-button [disabled]="!accountService.isUserLoggedIn()" [class.sh-active]="currentRoute ===
          'price-tool'" routerLink="price-tool" (click)="sidenav.close()">Preistool</button>
          </mat-list-item>
          <mat-list-item>
            <button mat-button [disabled]="!accountService.isUserLoggedIn()" (click)="openFeedbackDialog()">Feedback
            </button>
          </mat-list-item>
        </mat-nav-list>
      </section>
    </sh-content-container>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="head-nav-wrapper">
      <sh-top-nav (sidenavToggle)="sidenav.toggle()"></sh-top-nav>
      <div class="sh-border-bottom-accent"></div>
      <sh-cdk-menu-navbar class="sticky-top"></sh-cdk-menu-navbar>
      <div class="sh-border-bottom-accent"></div>
    </div>
    <div class="sh-main__container">
      <router-outlet></router-outlet>
    </div>
    <sh-footer class="mt-auto sh-site-footer"></sh-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
