import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sh-locked-modal',
  templateUrl: './locked-modal.component.html',
  styleUrls: ['./locked-modal.component.scss']
})
export class LockedModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
