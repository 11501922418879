import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
const LANG_KEY = 'appLang';
@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(private router: Router) { }

  setLanguage(lang: string = 'de | en'): void {
    localStorage.setItem(LANG_KEY, lang);
    location.reload();
  }

  getCurrentLanguage(): string {
    return localStorage.getItem(LANG_KEY);
  }

  loadLanguage(): void {
  }
}
