<sh-content-container [heading]="'Feedback'" width="500"
                      height="800">
  <form [formGroup]="feedbackForm" (submit)="sendFeedback()" style="width: 100%; padding: 10px;">
    <mat-form-field appearance="standard" style="width: 100%;">
      <mat-label>Nutzername</mat-label>
      <input matInput [formControl]="usernameControl" required>
    </mat-form-field>
    <mat-form-field appearance="standard" style="width: 100%;">
      <mat-label>Bereich wählen</mat-label>
      <mat-select [formControl]="topicsControl" required>
        <mat-option *ngFor="let topic of topics" [value]="topic">{{topic}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="standard" style="width: 100%; height: 100px;">
      <mat-label>Feedback (max. 1000 Zeichen)</mat-label>
      <textarea matInput maxlength="1000" [formControl]="contentControl" required
                (keyup)="charCounter()"></textarea>
      <label style="font-size:0.8em;">Noch {{textAreaLength}} Zeichen</label>
    </mat-form-field>
    <div class="col mt-4">
      <h5>Bild wählen</h5>
      <input type="file" name="fileInput" (change)="dropped($event)"/>
      <img id="imgSrc" #imgSrc>
    </div>
    <div class="text-center mt-3">
      <label class="sh-color-accent">
        Dein Browser ist {{currentBrowser}}
      </label>
    </div>
    <div class="mt-5 text-center">
      <button class="sh-btn sh-btn-raised-info" type="submit"  [disabled]="!feedbackForm.valid">Feedback
        senden</button>
    </div>
  </form>
</sh-content-container>
<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon>close</mat-icon>
</button>
