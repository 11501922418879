import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges, TemplateRef,
  ViewChild
} from "@angular/core";
import { ShopItemPriceEntry } from "@starhead/entities/shops/shop-item-price-entry";
import { FormBuilder, FormControl, FormGroup, NgForm, NgModel } from "@angular/forms";
import { Commodity } from "@starhead/entities/trading/commodity";
import { Shop } from "@starhead/entities/shops/shop";
import { CelestialObject } from "@starhead/entities/celestial-objects/celestial-object";
import { Location } from "@starhead/entities/location";
import { CelestialObjectService } from "@starhead/service/celestial-object.service";
import { LocationService } from "@starhead/service/location.service";
import { ShopService } from "@starhead/service/tradingServices/shop.service";
import { combineLatest, combineLatestAll, forkJoin, Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ShopItemTransferDto } from "@starhead/core/dtos/shop-item-transfer-dto";
import { CommodityTradeType } from "@starhead/core/interfaces/enums/commodity-trade-type";

export const _filterLocations = (opt: Location[], value: string): Location[] => {
  const filterValue = value.toLowerCase();
  return opt.filter(l => l.name.toLowerCase().includes(filterValue));
}

export const _filterCommoties = (opt: ShopItemPriceEntry[], value: string): Commodity[] => {
  const filter = value.toLowerCase();
  const commodities: Commodity[] = [];
  opt.map(item => {
    commodities.push(item.commodity);
  });
  const filtered = commodities.filter(c => c.name.toLowerCase().includes(filter))
    .sort();
  return filtered;
}

export const _filter =  <T extends object, K extends keyof T>(opt: T[], PropertyName: K, value: string): T[] => {
  const filterValue = value.toLowerCase();
  return opt.filter(x => (x[PropertyName] as unknown as string).toLowerCase().includes(filterValue))
}

@Component({
  selector: 'sh-shop-item-price',
  templateUrl: './shop-item-price.component.html',
  styleUrls: ['./shop-item-price.component.scss'],
})
export class ShopItemPriceComponent
  implements OnInit, AfterViewInit, OnChanges
{
  celestialObjects: CelestialObject[] = [];
  locations: Location[] = [];
  shopItems: ShopItemPriceEntry[] = [];
  isPriceDeviation: boolean = false;

  celestialObjects$ = this.celestialObjectService.getAllCelestialObjects();
  locations$ = this.locationService.locations$;
  shops$ = this.shopService.getShops();

  @Input() shops: Shop[];
  @Input() priceEntry: ShopItemTransferDto;
  @Input() commodities: Commodity[] = [];
  @Output() removeEntry = new EventEmitter<boolean>();
  @Output() priceEntryChange = new EventEmitter<ShopItemTransferDto>();

  priceEntryForm: FormGroup;
  @ViewChild('celestialObjectFilter', {static: true}) celestialObjectFilter: NgModel;
  @ViewChild('commodityFilter', {static: true}) commodityFilter: NgModel;

  celestialObjectSearch: string;
  locationSearch: string;
  commoditySearch: string;

  selectedCelestialObject: CelestialObject;
  selectedLocation: Location;
  selectedShop: Shop;
  selectedShopItemPriceEntry: ShopItemPriceEntry;

  celestialObjectOptions: Observable<CelestialObject[]>;
  locationOptions: Observable<Location[]>;
  commodityOptions: Observable<Commodity[]>;
  shopOptions: Observable<Shop[]>;

  buyBtnTooltip: TemplateRef<string>;
  sellBtnTooltip: string;
  CommodityTradeType: typeof CommodityTradeType = CommodityTradeType;

  constructor(
    private fb: FormBuilder,
    private celestialObjectService: CelestialObjectService,
    private locationService: LocationService,
    private shopService: ShopService
  ) {

  }

  ngOnInit(): void {
    this.initForm();
    if(this.priceEntry.shop?.id > 0) {
      console.log(this.priceEntryForm);
      this.prefillForm();
    }

    this.shops = [];
    this.celestialObjectService.getAllCelestialObjects().subscribe(result => {
      this.celestialObjects = result.sort((co, co1) => co.name < co1.name ? -1 : 1);
      this.celestialObjectOptions = this.priceEntryForm
        .get('celestialObjectSearch')!
        .valueChanges.pipe(
          startWith(''),
          map((value) => this.filterCelestialObjects(value))
        );
    });

    this.locationOptions = this.priceEntryForm
      .get('locationSearch')!
      .valueChanges.pipe(
        startWith(''),
        map((value) => this.filterLocations(value))
      );

    this.commodityOptions = this.priceEntryForm
      .get('commoditySearch')!
      .valueChanges.pipe(
        startWith(''),
        map((value) => this.filterCommodities(value))
      );

    this.shopOptions = this.priceEntryForm
      .get('shop')
      .valueChanges.pipe(
        startWith(''),
        map((value) => this.filterShops(value))
      );
  }

  ngAfterViewInit() {
    this.celestialObjectService.getAllCelestialObjects().subscribe(result => {
      this.celestialObjects = result.sort((co, co1) => co.name < co1.name ? -1 : 1);
      this.celestialObjectOptions = this.priceEntryForm
        .get('celestialObjectSearch')!
        .valueChanges.pipe(
          startWith(''),
          map((value) => this.filterCelestialObjects(value))
        );
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['commodities'] &&
      changes['commodities'].currentValue) {
      this.commodityOptions = this.commodityFilter
        .valueChanges
        .pipe(
          startWith(''),
          map((value) => this.filterCommodities(value))
        );
    }
  }

  initForm(){
    this.priceEntryForm = new FormGroup({
      celestialObject: new FormControl(['']),
      location: new FormControl(['']),
      shop: new FormControl(['']),
      commodityId: new FormControl(['']),
      pricePerItem: new FormControl([''], { updateOn: "blur" }),
      tradeType: new FormControl(['Buy']),
      celestialObjectSearch: new FormControl(['']),
      locationSearch: new FormControl(['']),
      commoditySearch: new FormControl(['']),
      shopSearch: new FormControl(['']),
    });

    this.priceEntryForm.patchValue(this.priceEntry);

    this.priceEntryForm.controls["pricePerItem"].valueChanges.subscribe(x => {
      this.priceEntry.pricePerItem = x;
      this.priceEntryChange.emit(this.priceEntry)

      // If entered price deviates more than 20% from current price, show warning
      const currentPrice = this.selectedShopItemPriceEntry?.pricePerItem * 100;
      if (x > 0 && currentPrice > 0) {
        const priceDeviation = Math.abs(currentPrice - x) / currentPrice;
        this.isPriceDeviation = priceDeviation > 0.2;
      }
    });

    this.priceEntryForm.controls['tradeType'].valueChanges.subscribe(x => {
      this.priceEntry.tradeType = x;
      this.priceEntryChange.emit(this.priceEntry);
      this.loadShopItemPriceEntries();
    })
  }

  patchValues(value: any) {
    this.priceEntry = value;
    // this.priceEntryChange.emit(this.priceEntry);
  }

  setSelectedCelestialObject(celestialObject: CelestialObject) {
    this.priceEntry.celestialObject = celestialObject;
    this.priceEntryChange.emit(this.priceEntry);
    this.locationService
      .getAllLocations(celestialObject.id)
      .subscribe((result) => {
        this.locations = result.sort((l, l1) => l.name < l1.name ? -1 : 1);
        this.locationOptions = this.priceEntryForm.controls[
          'locationSearch'
        ].valueChanges.pipe(
          startWith(''),
          map((value) => this.filterLocations(value))
        );
      });
  }
  setSelectedLocation(location: Location) {
    this.selectedLocation = location;
    this.priceEntry.location = this.selectedLocation;
    this.priceEntryChange.emit(this.priceEntry);
    this.shopService.getShops()
      .subscribe((result) => {
      this.shops = result
        .sort((s, s1) => s.name < s1.name ? 1 : -1)
        .filter(
        (s) => s.parent.id === this.selectedLocation.id
      );
      this.shopOptions = this.priceEntryForm.controls['shopSearch']
        .valueChanges
        .pipe(
          startWith(''),
          map(value => this.filterShops(value))
      );
    });
  }

  setSelectedShop(shop: Shop) {
    this.selectedShop = shop;
    this.priceEntry.shop = this.selectedShop;
    this.priceEntryChange.emit(this.priceEntry);
    this.loadShopItemPriceEntries();
  }

  setSelectedCommodity(commodity: Commodity) {
    this.selectedShopItemPriceEntry = this.shopItems.find(x => x.commodity.id === commodity.id);
    this.priceEntry.commodityId = commodity.id;
  }

  filterCelestialObjects(filter: string): CelestialObject[] {
    if (filter.length > 0) {
      // return _filterCelestialObject(this.celestialObjects, filter);
      return _filter(this.celestialObjects, 'name', filter);
    }
    return this.celestialObjects;
  }

  filterLocations(filter: string): Location[] {
    if (filter.length > 0) {
      return _filterLocations(this.locations, filter);
    }
    return this.locations;
  }

  filterCommodities(filter: string): Commodity[] {
    if (filter?.length > 0) {
      return _filterCommoties(this.shopItems, filter);
    }
    return this.commodities;
  }

  filterShops(filter: string): Shop[] {
    if(filter?.length > 0) {
      return _filter(this.shops, 'name', filter);
    }
    return this.shops;
  }

  loadShopItemPriceEntries() {
    this.commodities = [];
    this.shopItems = [];
    this.shopService.getShopItemPriceEntries(this.selectedShop.id).subscribe(result => {
      this.shopItems = result;
      this.selectedShopItemPriceEntry = null;
      this.shopItems.map((item) => {
        if(item.tradeType === this.priceEntry.tradeType) {
          this.commodities.push(item.commodity);
        }
      });
      this.commodities = this.commodities.sort((c, c2) => c.name > c2.name ? 1 : -1);
      this.commodityOptions = this.priceEntryForm
        .get('commoditySearch')
        .valueChanges.pipe(
        startWith(''),
        map((value) =>
          this.filterCommodities(value).sort((c, c1) => {
            if(c < c1) return 1;
            return 0;
          })
        )
      );
    });
  }

  private prefillForm() {
    if(this.priceEntry.celestialObject) {

      forkJoin({
        celestialObjects: this.celestialObjectService.getAllCelestialObjects(),
        locations: this.locationService.getAllLocations(),
        shops: this.shopService.getShops()
      }).subscribe((results) => {
        this.celestialObjects = results.celestialObjects;
        this.selectedCelestialObject = this.priceEntry.celestialObject;
        this.locations = results.locations.filter(l => l.parent.id === this.selectedCelestialObject.id);
        this.selectedLocation = this.priceEntry.location;
        this.locationOptions = this.priceEntryForm.controls[
          'locationSearch'
          ].valueChanges.pipe(
          startWith(''),
          map((value) => this.filterLocations(value))
        );
        this.selectedShop = this.priceEntry.shop;
        this.shops = results.shops.filter(s => s.parent.id === this.selectedLocation.id);
        this.loadShopItemPriceEntries();
        this.shopOptions = this.priceEntryForm.controls['shopSearch']
          .valueChanges
          .pipe(
            startWith(''),
            map(value => this.filterShops(value))
          );
      })

      // this.setSelectedCelestialObject(this.priceEntry.celestialObject);
      // this.setSelectedLocation(this.priceEntry.location);
      // this.setSelectedShop(this.priceEntry.shop);

      this.priceEntryForm.patchValue(this.priceEntry);

      console.log(this.priceEntry);
    }
  }

  compareWithFn(listOfItems, selectedItem) {
    return listOfItems && selectedItem && listOfItems.id === selectedItem.id;
  }
}
